import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { RoutesPath } from '../../../../routing/RoutesPath';
import { 
  useLazyGetDivisionsListQuery,
  useLazyGetDepartmentsListQuery,
  useLazyGetLocationsListQuery
} from '../../../dashboard/store/api';
import { useLazyGetCustomGroupsListQuery } from '../../store/api';
import { RootState } from '../../../../store/store';
import { setActiveDepartmentsList, setLiveCustomGroupsList, setActiveLocationsList, setActiveDivisionsList } from '../../../../store/slices/masterList';
import { clearAllChallengesFormFields, setChallengesFormField } from '../../../../store/slices/forms/challengesForm';

import PartOneCreateChallengePage from "./part-one-create-challenge";
import PartTwoCreateChallengePage from './part-two-create-challenge';
import { ENUM_PRE_DEFINED_CHALLENGE_TYPE, PRE_DEFINED_CHALLENGES_IMG_CONFIG } from '../types';

type Props = {

}

const CreateChallengesPage: React.FC<Props> = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {state: routeState}: any = useLocation();

  /* -------------------------------- REACT STATES ---------------------------------- */

  const { activeDepartmentsList, activeDivisionsList, activeLocationsList, liveCustomGroupsList } = useSelector((state: RootState) => state.masterList)

  const [onPartOne, setOnPartOne] = useState<number>(0);

  /* ------------------------- REDUX DISPATCHER, SELECTOR --------------------------- */

  const handleFormNavigation = (navigateTo: "part_one" | "part_two") => {
    if (navigateTo === "part_one") {
      setOnPartOne(1);
    } else {
      setOnPartOne(2);
    }
  }

  const [getDivisionsList, {data: divisionsListData}] = useLazyGetDivisionsListQuery()
  const [getDepartmentsList, {data: departmentsListData}] = useLazyGetDepartmentsListQuery()
  const [getLocationsList, {data: locationsListData}] = useLazyGetLocationsListQuery()
  const [getCustomGroupList, {
    data: customGroupsListData, 
    isSuccess: isSuccessGetGroups, 
    isLoading: isLoadingGetGroups,
    isError: isErrorGetGroups,
  }] = useLazyGetCustomGroupsListQuery();

  /* ----------------------------------- HANDLERS ------------------------------------ */

  const handleBackNavigation = () => {
    dispatch(clearAllChallengesFormFields());
    navigate(RoutesPath.CHALLENGES.ROOT);
  }

  // REDUX MANAGEMENT AT COMPONENT MOUNT
  useEffect(() => {
    if (routeState && routeState?.status === "draft") {
      if (routeState?.id) 
        dispatch(setChallengesFormField({field: "id", data: routeState?.id}));
      if (routeState?.name) 
        dispatch(setChallengesFormField({field: "name", data: routeState?.name}));
      if (routeState?.description) 
        dispatch(setChallengesFormField({field: "description", data: routeState?.description}));
      if (routeState?.startDate) 
        dispatch(setChallengesFormField({field: "startDate", data: new Date(routeState?.startDate)}));
      if (routeState?.endDate) 
        dispatch(setChallengesFormField({field: "endDate", data: new Date(routeState?.endDate)}));
      if (routeState?.image) 
        dispatch(setChallengesFormField({field: "image", data: routeState?.image}));
      if (routeState?.challengeType) 
        dispatch(setChallengesFormField({field: "challengeType", data: routeState?.challengeType}));
      if (routeState?.challengeGroupType) 
        dispatch(setChallengesFormField({field: "challengeGroupType", data: routeState?.challengeGroupType}));
      if (routeState?.groupIds) 
        dispatch(setChallengesFormField({field: "groupIds", data: routeState?.groupIds}));
      // if it's pre-defined challenge
      if (routeState?.preDefinedId !== null && typeof routeState?.preDefinedId === "number") {
        dispatch(setChallengesFormField({field: 'isPreDefined', data: true}));
        dispatch(setChallengesFormField({field: 'preDefinedChallengeId', data: routeState?.preDefinedId}));
      } else {
        dispatch(setChallengesFormField({field: 'isPreDefined', data: false}));
      }
    } else {
      dispatch(clearAllChallengesFormFields());
    }
    setOnPartOne(1);
  }, [routeState]);


  /* ------------------------- COMPONENT MOUNT - API CALLS --------------------------- */

  useEffect(() => {
    if (activeDivisionsList.length === 0) {
      getDivisionsList({isActive: true}).then((res:any)=>{
        const arr: any[] = []
        res?.data.forEach((itm:any) => {
          arr.push(itm)
        })
        dispatch(setActiveDivisionsList(arr))
      })
    }
    if (activeDepartmentsList.length === 0) {
      getDepartmentsList({isActive: true}).then((res:any)=>{
        const arr: any[] = []
        res?.data.forEach((itm:any) => {
          arr.push(itm)
        })
        dispatch(setActiveDepartmentsList(arr))
      })
    }
    if (activeLocationsList.length === 0) {
      getLocationsList({isActive: true}).then((res:any)=>{
        const arr: any[] = []
        res?.data.forEach((itm:any) => {
          arr.push(itm)
        })
        dispatch(setActiveLocationsList(arr))
      })
    }
    if (liveCustomGroupsList.length === 0) {
      getCustomGroupList({}).then((res:any)=>{
        let arr: any[] = Object.values(res?.data?.data?.groups || {});
        arr = arr.filter((item: any) => item.status === "live");
        dispatch(setLiveCustomGroupsList(arr));
      })
    }
  }, [])


  return (
    <div className='blank-page-view py-2 px-12'>

      {/* --------------------------------------- HEADER ------------------------------------------ */}

      <div className='back-header-btn mt-8' style={{marginLeft: '0px'}}>
        <button 
          type='button' 
          className='p-2 back-left-arrow-btn-wrap'
          onClick={handleBackNavigation}
          >
          <div className='back-left-arrow-btn' /> 
        </button>
        <h6 className='m-0 mx-4 fs-4 font-weight-normal'><u>Challenges</u></h6>
      </div>

      {/* -------------------------------------- BODY - PAGES ---------------------------------------- */}

      {
        (onPartOne === 1) && (
          <PartOneCreateChallengePage 
            handleFormNavigation={handleFormNavigation}
            /> 
        ) 
      }
      {
        (onPartOne === 2) && (
          <PartTwoCreateChallengePage 
            handleFormNavigation={handleFormNavigation}
            /> 
        )
      }

    </div>
  )
}

export default CreateChallengesPage;