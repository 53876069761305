export enum ENUM_PRE_DEFINED_CHALLENGE_TYPE {
  _1 = "pre_defined_challenge_1",
  _2 = "pre_defined_challenge_2",
  _3 = "pre_defined_challenge_3"
}

export type PreDefinedChallengeType = number;

export const DEFAULT_CHALLENGE_IMG_CONFIG = {
  _1: "https://mynzo.s3.ap-south-1.amazonaws.com/challenges-assets/challenges_default_img_1.png",
  _2: "https://mynzo.s3.ap-south-1.amazonaws.com/challenges-assets/challenges_default_img_18.png",
  _3: "https://mynzo.s3.ap-south-1.amazonaws.com/challenges-assets/challenges_default_img_19.png",
  _4: "https://mynzo.s3.ap-south-1.amazonaws.com/challenges-assets/challenges_default_img_20.png",
}

export const PRE_DEFINED_CHALLENGES_IMG_CONFIG = {
  _1: "https://mynzo.s3.ap-south-1.amazonaws.com/challenges-assets/challenges_default_img_5.png",
  _2: "https://mynzo.s3.ap-south-1.amazonaws.com/challenges-assets/challenges_default_img_3.png",
  _3: "https://mynzo.s3.ap-south-1.amazonaws.com/challenges-assets/challenges_default_img_4.png",
  _4: "https://mynzo.s3.ap-south-1.amazonaws.com/images/challenges_default_img_6.png",
  _5: "https://mynzo.s3.ap-south-1.amazonaws.com/images/challenges_default_img_7.png",
  _6: "https://mynzo.s3.ap-south-1.amazonaws.com/images/challenges_default_img_8.png",
  _7: "https://mynzo.s3.ap-south-1.amazonaws.com/images/challenges_default_img_9.png",
  _8: "https://mynzo.s3.ap-south-1.amazonaws.com/images/challenges_default_img_10.png",
  _9: "https://mynzo.s3.ap-south-1.amazonaws.com/images/challenges_default_img_11.png",
  _10: "https://mynzo.s3.ap-south-1.amazonaws.com/images/challenges_default_img_12.png",
  _11: "https://mynzo.s3.ap-south-1.amazonaws.com/images/challenges_default_img_13.png",
  _12: "https://mynzo.s3.ap-south-1.amazonaws.com/images/challenges_default_img_14.png",
  _13: "https://mynzo.s3.ap-south-1.amazonaws.com/images/challenges_default_img_15.png",
  _14: "https://mynzo.s3.ap-south-1.amazonaws.com/images/challenges_default_img_16.png",
  _15: "https://mynzo.s3.ap-south-1.amazonaws.com/images/challenges_default_img_17.png",
}

export enum CHALLENGE_PARTICIPANT_TYPE {
  INDIVIDUAL = "INDIVIDUAL",
  GROUP = "GROUP"
}

export enum CHALLENGE_CATEGORY_TYPE {
  DEPARTMENT = "DEPARTMENT",
  DIVISION = "DIVISION",
  LOCATION = "LOCATION",
  GROUP = "CUSTOM_GROUP"
}

export type ChallengeParticipantType = CHALLENGE_PARTICIPANT_TYPE;

export type ChallengeCategoryType = CHALLENGE_CATEGORY_TYPE;

export const CATEGORY_PRE_DEFINED_CHALLENGE = {
  _1: {
    ID: 1,
    CATEGORY: "Waste & Water"
  },
  _2: {
    ID: 2,
    CATEGORY: "Energy Saving"
  },
  _3: {
    ID: 3,
    CATEGORY: "Diet"
  }
}

export enum ChallengeActionType {
  UPLOAD_SINGLE_IMAGE = "UPLOAD_SINGLE_IMAGE",
  USER_MARK = "USER_MARK",
  USER_MARK_DAILY = "USER_MARK_DAILY",
  POST_ON_FEED = "POST_ON_FEED"
}

export const ChallengeActionTypeConfig = {
  UPLOAD_SINGLE_IMAGE: {
    type: ChallengeActionType.UPLOAD_SINGLE_IMAGE,
    label: "Upload a picture",
  },
  USER_MARK: {
    type: ChallengeActionType.USER_MARK,
    label: "Mark as complete"
  },
  USER_MARK_DAILY: {
    type: ChallengeActionType.USER_MARK_DAILY,
    label: "Mark as complete till duration of challenge"
  },
  POST_ON_FEED: {
    type: ChallengeActionType.POST_ON_FEED,
    label: "Post on feed"
  }
}

// !!!!!!!!!!!-------- DO NOT CHANGE ANY ID, ADD OR COMMENT IF NEED BE, BUT DO NOT CHANGE EXISTING MAPPED IDs. -----!!!!!!!!!!!!!!!!!!!!!!!!!

export const PRE_DEFINED_CHALLENGES_CONFIG = {
  // _1: {
  //   ID: 1,
  //   CATEGORY_ID: 1,
  //   NAME: "Plant a seed Challenge",
  //   DESCRIPTION: `Join the "Plant a Seed Challenge" and be a part of the global effort to combat climate change! This challenge invites you to plant trees or other greenery to contribute to reforestation and promote environmental sustainability. Together, we can make a positive impact on our planet's future!`,
  //   IMAGE: PRE_DEFINED_CHALLENGES_IMG_CONFIG._1,
  //   IS_MANDATORY: 1,
  //   COINS_PER_USER: 20,
  //   ACTION_TYPE: ChallengeActionType.UPLOAD_SINGLE_IMAGE,
  //   ACTION_TEXT: "Upload a pic of planted seed"
  // },
  // _2: {
  //   ID: 2,
  //   CATEGORY_ID: 1,
  //   NAME: "Composting Challenge ",
  //   DESCRIPTION: `Join the "Composting Challenge" and be a champion for sustainability! This challenge encourages you to start composting organic waste to reduce landfill waste and enrich soil. Begin by setting up a composting system at home or in your community, then compost food scraps, yard waste, and other biodegradable materials`,
  //   IMAGE: PRE_DEFINED_CHALLENGES_IMG_CONFIG._2,
  //   IS_MANDATORY: 1,
  //   COINS_PER_USER: 20,
  //   ACTION_TYPE: ChallengeActionType.UPLOAD_SINGLE_IMAGE,
  //   ACTION_TEXT: "Upload a pic of your compost pit"
  // }, 
  // _3: {
  //   ID: 3,
  //   CATEGORY_ID: 1,
  //   NAME: "Upload a painting on global warming",
  //   DESCRIPTION: `Upload a painting celebrating our planet's beauty and inspiring environmental action. Capture sustainable living, biodiversity, or renewable energy. Share your masterpiece and inspire others to join the movement for a greener future!`,
  //   IMAGE: PRE_DEFINED_CHALLENGES_IMG_CONFIG._3,
  //   IS_MANDATORY: 1,
  //   COINS_PER_USER: 20,
  //   ACTION_TYPE: ChallengeActionType.UPLOAD_SINGLE_IMAGE,
  //   ACTION_TEXT: "Upload a pic of your painting"
  // }, 
  _4: {
    ID: 4,
    CATEGORY_ID: 1,
    NAME: "Waste to Plant Food",
    DESCRIPTION: `Did you know that 28% of waste deposited in landfills can be composted? It's also an easy way to spruce up your plants. If you don't have a yard, repurpose a trash can as your new compost bin. Add daily food scraps, fruits, vegetables, egg shells and even your coffee grounds!`,
    IMAGE: PRE_DEFINED_CHALLENGES_IMG_CONFIG._2,
    IS_MANDATORY: 1,
    COINS_PER_USER: 40,
    ACTION_TYPE: ChallengeActionType.UPLOAD_SINGLE_IMAGE,
    ACTION_TEXT: "Upload a pic of your compost pit"
  }, 
  _5: {
    ID: 5,
    CATEGORY_ID: 2,
    NAME: "Power Down Gadgets",
    DESCRIPTION: `Did you know that switching off your gadgets makes them run more efficiently? Set all your devices to power-saving or energy-saving mode and switch them off when you don't need them.`,
    IMAGE: PRE_DEFINED_CHALLENGES_IMG_CONFIG._4,
    IS_MANDATORY: 1,
    COINS_PER_USER: 10,
    ACTION_TYPE: ChallengeActionType.USER_MARK_DAILY,
    ACTION_TEXT: "Do this everyday for the duration of the challenge"
  }, 
  _6: {
    ID: 6,
    CATEGORY_ID: 2,
    NAME: "Spin the Fan",
    DESCRIPTION: `Did you know that using a fan allows you to reduce the temperature of your AC without compromising on comfort? Turn the AC temperature setting up by 4degC and turn on the fan. It's just as comfortable, and guess what? You're also saving on electricity bills! `,
    IMAGE: PRE_DEFINED_CHALLENGES_IMG_CONFIG._5,
    IS_MANDATORY: 1,
    COINS_PER_USER: 20,
    ACTION_TYPE: ChallengeActionType.USER_MARK_DAILY,
    ACTION_TEXT: "Do this everyday for the duration of the challenge"
  }, 
  _7: {
    ID: 7,
    CATEGORY_ID: 2,
    NAME: "LED Brights",
    DESCRIPTION: `Did you know that LEDs use up to 90% less energy and last up to 25 times longer than traditional incandescent bulbs? They're also the most cost-effective option long-term. Switch out the bulbs in your home!`,
    IMAGE: PRE_DEFINED_CHALLENGES_IMG_CONFIG._6,
    IS_MANDATORY: 1,
    COINS_PER_USER: 30,
    ACTION_TYPE: ChallengeActionType.USER_MARK,
    ACTION_TEXT: "Switch to LED and then 'Mark as completed'"
  }, 
  _8: {
    ID: 8,
    CATEGORY_ID: 1,
    NAME: "Save Food",
    DESCRIPTION: `Did you know that the average person wastes around 131 Kg of food every year? Don't throw out those leftovers, they make for a great meal the next day!`,
    IMAGE: PRE_DEFINED_CHALLENGES_IMG_CONFIG._7,
    IS_MANDATORY: 1,
    COINS_PER_USER: 10,
    ACTION_TYPE: ChallengeActionType.UPLOAD_SINGLE_IMAGE,
    ACTION_TEXT: "Upload a pic of waste bin"
  }, 
  _9: {
    ID: 9,
    CATEGORY_ID: 1,
    NAME: "Air Dry",
    DESCRIPTION: `Did you know the dryer is bad for your clothes? Go old school with a drying rack or clothesline and do your wardrobe and the environment a favour.`,
    IMAGE: PRE_DEFINED_CHALLENGES_IMG_CONFIG._8,
    IS_MANDATORY: 1,
    COINS_PER_USER: 60,
    ACTION_TYPE: ChallengeActionType.UPLOAD_SINGLE_IMAGE,
    ACTION_TEXT: "Upload a pic of clothes on rack/line"
  }, 
  _10: {
    ID: 10,
    CATEGORY_ID: 1,
    NAME: "Better Shower",
    DESCRIPTION: `Did you know that an outdated showerhead can hamper the quality of your shower? Upgrading your shower head is inexpensive, and will seriously improve the time you spend in there. Water savings, better filtration and pressure, and lower bills!`,
    IMAGE: PRE_DEFINED_CHALLENGES_IMG_CONFIG._9,
    IS_MANDATORY: 1,
    COINS_PER_USER: 70,
    ACTION_TYPE: ChallengeActionType.UPLOAD_SINGLE_IMAGE,
    ACTION_TEXT: "Upload a pic of the upgraded showerhead"
  }, 
  _11: {
    ID: 11,
    CATEGORY_ID: 3,
    NAME: "One Day Meatless",
    DESCRIPTION: `Did you know that reducing meat consumption by half could reduce food-system emissions by nearly one-quarter? Start small, choose one day of the week to keep meat off your plate. Do good for your health and the planet at the same time!`,
    IMAGE: PRE_DEFINED_CHALLENGES_IMG_CONFIG._10,
    IS_MANDATORY: 1,
    COINS_PER_USER: 70,
    ACTION_TYPE: ChallengeActionType.UPLOAD_SINGLE_IMAGE,
    ACTION_TEXT: "Upload a pic of plate without meat "
  }, 
  _12: {
    ID: 12,
    CATEGORY_ID: 2,
    NAME: "Switch Off",
    DESCRIPTION: `Did you know buildings and offices are responsible for nearly 40% of energy-related carbon emissions globally? Your office electronics need a break too! Turn off your work computers, printers and office lights when you pack up for the day.`,
    IMAGE: PRE_DEFINED_CHALLENGES_IMG_CONFIG._11,
    IS_MANDATORY: 1,
    COINS_PER_USER: 10,
    ACTION_TYPE: ChallengeActionType.USER_MARK_DAILY,
    ACTION_TEXT: "Do this everyday for the duration of the challenge"
  }, 
  _13: {
    ID: 13,
    CATEGORY_ID: 1,
    NAME: "Ebilling",
    DESCRIPTION: `Did you know that skipping paper receipts would save 12 billion pounds of carbon dioxide (CO2), the equivalent of one million cars on the road? Make the switch to online billing for all your purchases and bank statements!`,
    IMAGE: PRE_DEFINED_CHALLENGES_IMG_CONFIG._12,
    IS_MANDATORY: 1,
    COINS_PER_USER: 10,
    ACTION_TYPE: ChallengeActionType.USER_MARK,
    ACTION_TEXT: "Skip paper receipt and then 'Mark as completed'"
  }, 
  _14: {
    ID: 14,
    CATEGORY_ID: 2,
    NAME: "Off Power Strip",
    DESCRIPTION: `Did you know that almost every appliance and electronic device you have plugged in at home is using some electricity and adding to your utility bill, even when it isn't in use? Use a power strip to stop this from happening, and turn it off when you're not using your devices.`,
    IMAGE: PRE_DEFINED_CHALLENGES_IMG_CONFIG._13,
    IS_MANDATORY: 1,
    COINS_PER_USER: 10,
    ACTION_TYPE: ChallengeActionType.USER_MARK_DAILY,
    ACTION_TEXT: "Do this everyday for the duration of the challenge"
  }, 
  _15: {
    ID: 15,
    CATEGORY_ID: 1,
    NAME: "Recycled Papers",
    DESCRIPTION: `Did you know that 42 million tons of toilet paper are used globally every year? And that the production of this requires 712 million trees? Toilet paper made with recyled paper produces around 30% fewer carbon emissions when compared to traditional loo roll, and don't worry, it's every bit as hygienic. Make the switch to green your bathroom routine.`,
    IMAGE: PRE_DEFINED_CHALLENGES_IMG_CONFIG._14,
    IS_MANDATORY: 1,
    COINS_PER_USER: 20,
    ACTION_TYPE: ChallengeActionType.USER_MARK,
    ACTION_TEXT: "Switch to a recycled paper produce and then 'Mark as completed'"
  }, 
  _16: {
    ID: 16,
    CATEGORY_ID: 2,
    NAME: "Thermostat",
    DESCRIPTION: `Did you know that smart thermostats can reduce energy bills by up to 23%? They're relatively inexpensive, given the energy savings over a year. Buy yours today and start saving!`,
    IMAGE: PRE_DEFINED_CHALLENGES_IMG_CONFIG._15,
    IS_MANDATORY: 1,
    COINS_PER_USER: 90,
    ACTION_TYPE: ChallengeActionType.UPLOAD_SINGLE_IMAGE,
    ACTION_TEXT: "Upload a pic of the installed Smart Thermostat"
  }, 
  // _17: {
  //   ID: 17,
  //   CATEGORY_ID: 1,
  //   NAME: "Zero-E Energy",
  //   DESCRIPTION: `Did you know that renewable energy sources are replenished by nature and emit little to no greenhouse gases or pollutants? Alter your current utility plan or select a new provider altogether for renewable energy at home. Do your research to find a reliable provider in your locality, and the next time friends come over, you can brag that you're living in the future!`,
  //   IMAGE: PRE_DEFINED_CHALLENGES_IMG_CONFIG._3,
  //   IS_MANDATORY: 1,
  //   COINS_PER_USER: 90,
  //   ACTION_TYPE: ChallengeActionType.USER_MARK,
  //   ACTION_TEXT: "Mark as completed"
  // }
}

