import moment from "moment"
import { useNavigate } from "react-router-dom"

import { ChallengesFilterType } from "../../../../store/slices/filters"
import { EditPenIcon, TrashIcon2 } from "../../../../../_metronic/assets/icons"
import { RoutesPath } from "../../../../routing/RoutesPath"
import { 
  CHALLENGE_PARTICIPANT_TYPE, 
  ChallengeCategoryType, 
  ChallengeParticipantType 
} from "../types"


type groupDataType = {
  id: number
  name: string
  description: string
  image: string
  startDate: string
  endDate: string | null
  challengeType: ChallengeParticipantType
  challengeGroupType?: ChallengeCategoryType
  groupIds: number[],
  status: ChallengesFilterType
  participantCount: number
  actionType: string
  actionText: string | null
  coinPerUser: number
  preDefinedId: number | null
}

type propsType = {
  groupData: groupDataType
  showDeleteModal: Function
}

export const CardChallenges = (props: propsType) => {
  const {
    groupData,
    showDeleteModal
  } = props;

  const navigate = useNavigate();

  return (
    <button
      key={groupData.id}
      type="button"
      className="remove-btn-styles bordered-grey card-custom-groups-div"
      style={{
        textAlign: 'left', 
        cursor: groupData.status === 'draft' ? 'default' : 'pointer',
        display: 'flex',
        flexDirection: 'column'
      }}
      onClick={() => {
        if (groupData.status !== 'draft')
          navigate(RoutesPath.CHALLENGES.CHALLENGE_DETAILS, {state: groupData})
      }}
      >       
      {/* <div  className="bordered-grey card-custom-groups-div"> */}
        <div className="card-custom-groups-header-div d-flex justify-content-between align-items-center position-relative w-100">
          <div className="d-flex align-items-center w-100">
            <img 
              alt={groupData.image}
              src={groupData.image}
              height={36}
              style={{marginRight: '10px', borderRadius: '8px'}}
              />
            <div style={{maxWidth: groupData.status === "draft" ? '70%' : '85%'}}>
              <h2 className='d-flex fs-3 m-0 bold align-items-center position-relative' style={{color: "#5C5C5C", paddingRight: '10px'}}>
                {groupData.name}
              </h2>
              <p className='fs-6 m-0' style={{color: "#636363"}}>{groupData.participantCount} members</p>
            </div>
          </div>
          {
            groupData.status === "draft" ?
            <div>
              <button
                type="button"
                className="remove-btn-styles"
                onClick={() => {
                  navigate(RoutesPath.CHALLENGES.CREATE_CHALLENGES,{state: groupData})
                }}
                >
                <EditPenIcon 
                  color="#84C0D2"
                  height={22}
                  width={22}
                  style={{padding: '2px'}}
                  />
              </button>
              {/* {
                ["upcoming", "draft"].includes(groupData.status) &&
                <button
                  type="button"
                  className="remove-btn-styles"
                  style={{marginLeft: '5px'}}
                  onClick={() => {
                    setTimeout(showDeleteModal(groupData.id), 200);
                  }}
                  >
                  <TrashIcon2 
                    color="#84C0D2"
                    height={26}
                    width={26}
                    style={{padding: '2px'}}
                    />
                </button>
              } */}
            </div> : 
            <div />
          }
          <div className="card-challenge-participant-type-pill">
            <p className="m-0">
              { groupData.challengeType === CHALLENGE_PARTICIPANT_TYPE.INDIVIDUAL ? "Individual" : "Group" }
            </p>
          </div>
        </div>
        <div className="card-custom-groups-body-div">
          <ul className='m-0 mb-2 create-challenges-bullet-points' style={{paddingLeft: '15px'}}>
            { groupData.actionText  && <li className='m-0 mb-2 fs-7'><p className="m-0 fs-6">{groupData.actionText}</p></li> }
            { groupData.coinPerUser && <li className='m-0 mb-2 fs-7'><p className="m-0 fs-6">{groupData.coinPerUser} Mynzo coins as rewards</p></li> }
            <li>
              <div className="d-flex mb-4">
                <p className='fs-6 m-0'>{moment(groupData.startDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}</p>
                {
                  groupData.endDate !== null &&
                  <p className='fs-6 m-0 mx-1'>- {moment(groupData.endDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}</p>
                }
              </div>
            </li>
          </ul>
          {/* <p className='fs-6 m-0 mt-2' style={{color: "#1A1A1A"}}>{groupData.description?.slice(0,70)}{groupData.description?.length>=70&&'...'}</p> */}
          {/* <div className="d-flex my-4">
          </div> */}
        </div>
      {/* </div> */}
    </button>
  )
}