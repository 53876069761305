/* eslint-disable no-useless-return */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

import { useLazyGetQuizByIdQuery } from '../../store/api';
import { RoutesPath } from '../../../../routing/RoutesPath';
import { CalendarMonthIcon, ChallengeGroupIcon, CustomGroupTabIcon, MynzoCoinIcon, NoImageIcon } from '../../../../../_metronic/assets/icons';

type PropsType = {

}

const IconCircleContainer = (props: any) => {
  const {children, content, isPadding=true} = props;
  return isPadding ? (
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
      <div style={{
        backgroundColor: '#E2F3FB', 
        height: '32px',
        width: '32px', 
        borderRadius: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
        }}>
        {children}
      </div>
      <p className='m-0 px-2' style={{color: '#3A3A3A'}}>
        {content}
      </p>
    </div>
  ) : (
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
      {children}
      <p className='m-0 px-2' style={{color: '#3A3A3A'}}>
        {content}
      </p>
    </div>
  )
}

const RenderQuizHeaderRow = (props: any) => (
  <div 
    className='px-10 py-3 fs-6 d-flex justify-content-between' 
    style={{
      border: '1px solid #d8d8d8', 
      borderWidth: '1px 0px 0px',
      fontWeight: '700',
    }}
    >
    <p className='m-0' style={{color: '#5C5C5C', paddingLeft: '10px'}}>Participant&apos;s Name</p>
    <div className='d-flex justify-content-between' style={{width: '60%'}}>
      <p className='m-0' style={{color: '#5C5C5C', width: '25%', textAlign: 'center'}}>Participated On</p>
      <p className='m-0' style={{color: '#5C5C5C', width: '25%', textAlign: 'center'}}>Correct Answers</p>
      <p className='m-0' style={{color: '#5C5C5C'}}>Rank on Leaderboard</p>
    </div>
  </div>
)

const RenderQuizRow = (props: any) => {
  const {item} = props;
  return (
    <div 
      className='px-10 py-3 fs-6 d-flex justify-content-between' 
      style={{
        border: '1px solid #d8d8d8', 
        borderWidth: '1px 0px 0px',
      }}
      >
      <div className='d-flex'>
        <p style={{marginLeft: '-18px', marginRight: '20px', marginBottom: '0px', color: '#757575'}}>{item.index}</p>
        <div className='d-flex align-items-center'>
          <img alt="name" src={item.profileImage} style={{height: '24px', width: '24px', borderRadius: '12px', marginRight: '10px', objectFit: 'cover'}} /> 
          <p className='m-0' style={{color: '#5C5C5C', fontWeight: '600'}}>{item.userName}</p>
        </div>
      </div>
      <div className='d-flex justify-content-between' style={{width: '60%'}}>
        <p className='m-0' style={{color: '#5C5C5C', width: '25%', textAlign: 'center'}}>{moment(item.participatedOn).format("DD/MM/YYYY")}</p>
        <p className='m-0' style={{color: '#5C5C5C', width: '25%', textAlign: 'center'}}>{item.correctCount}/{item.totalQuestion}</p>
        <p className='m-0' style={{color: '#5C5C5C', width: '25%', textAlign: 'center'}}>{item.rank}</p>
      </div>
    </div>
  )
}

const RenderQuizStatusFlag = (props: any) => {
  const {status} = props;
  let bkgColor = '#A53838';
  let statusText = 'Ended';
  switch (status) {
    case "upcoming":
      bkgColor = '#DCA519';
      statusText = 'Upcoming';
      break;
    case "live":
      bkgColor = '#04BD38';
      statusText = "Live";
      break;
    default:
      bkgColor = '#A53838';
      statusText = 'Ended';
      break;
  }
  return (
    <div 
      className='m-0'
      style={{
        position: 'absolute',
        padding : '0px 20px',
        borderRadius: "8px 0px 10px 0px",
        backgroundColor: bkgColor,
        color: 'white',
        fontWeight: '700'
      }}
      >
      <p className='m-0'>{statusText}</p>
    </div>
  )
}

const QuizDetailsPage: React.FC<PropsType> = () => {

  const navigate = useNavigate();
  const {state: routeState}: any  = useLocation();

  const [quizArr, setQuizArr] = useState<any[]>([]);

  const [getQuizById, {
    data: quizData,
    isLoading: isLoadingGetQuizById,
    isSuccess: isSuccessGetQuizById,
    isError: isErrorGetQuizeById
  }] = useLazyGetQuizByIdQuery();

  useEffect(() => {
    if (routeState.id)
      getQuizById({id: routeState.id})
  }, [routeState.id])

  useEffect(() => {
    if (quizData) {
      setQuizArr(quizData?.data?.leaderboard || [])
    }
  }, [quizData])

  return (
    <div className='blank-page-view py-2 px-12 mb-10'>

      {/* --------------------------------------- HEADER ------------------------------------------ */}

      <div className='back-header-btn mt-8' style={{marginLeft: '0px'}}>
        <button 
          type='button' 
          className='p-2 back-left-arrow-btn-wrap'
          onClick={()=>{navigate(RoutesPath.QUIZZES.ROOT)}}
          >
          <div className='back-left-arrow-btn' /> 
        </button>
        <h6 className='m-0 mx-4 fs-4 font-weight-normal'><u>Quizzes</u></h6>
      </div>

      {/* ----------------------------------------- BODY ----------------------------------------------- */}

      <div className='mt-6 bordered-grey'>
        <RenderQuizStatusFlag status={routeState.status}/>
        <div className='d-flex p-4 pt-8'>
          <img
            alt={routeState.imgUrl}
            src={routeState.imgUrl}
            style={{
              height: '70px',
              borderRadius: '8px',
            }}
            />
          <div className='px-4 w-100'>
            <h2 className='m-0 mt-1 bold' style={{color: '#5C5C5C'}}>
              {routeState.title}
            </h2>
            <div className='mt-3 fs-6 d-flex align-items-center justify-content-between'>
              <IconCircleContainer
                content={`${moment(routeState.startDate).format('DD/MM/YYYY')} ${routeState?.endDate ? `- ${moment(routeState.endDate).format('DD/MM/YYYY')}` : ''}`}
                >
                <CalendarMonthIcon color="#4C707A" />
              </IconCircleContainer>
              <IconCircleContainer
                content={`${quizData?.data?.coinsPerUser || '--'} Mynzo Coins to all participants`}
                isPadding={false}
                >
                <MynzoCoinIcon height={26} width={26} />
              </IconCircleContainer>
              <IconCircleContainer
                content={`${routeState.participantCount} participants`}
                >
                <CustomGroupTabIcon color="#4C707A" height={18} width={18} />
              </IconCircleContainer>
            </div>
          </div>
        </div>
        <div 
          className='my-2 py-3 d-flex justify-content-evenly align-items-center' 
          style={{backgroundColor: '#F0F0F0'}}
          >
          { routeState.description && <p className='m-0 mx-4 fs-6' style={{color: '#4F4F4F', textAlign: 'center'}}>{routeState.description}</p> }
        </div>
        
        <div>
          <RenderQuizHeaderRow name="Group Name" />
          {
            quizArr.map((item, index) => (
              <RenderQuizRow key={`${item.userId + index}`} item={{index: index+1, ...item}} />
            ))
          }
        </div>

      </div>

    </div>
  )
}

export default QuizDetailsPage;
/* eslint-disable no-useless-return */