import { createSlice } from "@reduxjs/toolkit";
import { 
  ChallengeActionType,
  DEFAULT_CHALLENGE_IMG_CONFIG
} from "../../../modules/configuration/challenges/types";
import { 
  CHALLENGE_CATEGORY_TYPE, 
  CHALLENGE_PARTICIPANT_TYPE, 
  ChallengeCategoryType, 
  ChallengeParticipantType 
} from "../../../modules/configuration/challenges/types";

type FORM_FIELDS = 
  "id" |
  "name" |
  "startDate" |
  "endDate" |
  "description" |
  "image" |
  "challengeType" |
  "challengeGroupType" |
  "groupIds" |
  "isPreDefined" |
  "preDefinedChallengeId" | 
  "preDefinedChallengeCategoryId" |
  "isMandatory" |
  "coinPerUser" |
  "actionType" |
  "actionText"

export type FormType = {
  id: number | null,
  name: string,
  startDate?: string,
  endDate?: string,
  description: string,
  image: string,
  challengeType: ChallengeParticipantType,
  challengeGroupType: ChallengeCategoryType,
  groupIds: number[],
  isPreDefined: boolean,
  preDefinedChallengeId: number,
  preDefinedChallengeCategoryId: number,
  isMandatory: number,
  coinPerUser: number,
  actionType: string,
  actionText: string
}

export type SetFormActionType = {
  payload: {
    field: FORM_FIELDS,
    data: any,
  },
  type: string
}

const initialState: FormType = {
  id: null,
  name: '',
  startDate: undefined,
  endDate: undefined,
  description: '',
  image: DEFAULT_CHALLENGE_IMG_CONFIG._1,
  challengeType: CHALLENGE_PARTICIPANT_TYPE.INDIVIDUAL,
  challengeGroupType: CHALLENGE_CATEGORY_TYPE.LOCATION,
  groupIds: [],
  isPreDefined: true,
  preDefinedChallengeId: 4,
  preDefinedChallengeCategoryId: 0,
  isMandatory: 1,
  coinPerUser: 20,
  actionType: ChallengeActionType.UPLOAD_SINGLE_IMAGE,
  actionText: ""
}

export const challengesFormSlice = createSlice({
  name: 'challengesForm',
  initialState,
  reducers: {
    setChallengesFormField: (state: any, action: SetFormActionType) => {
      state[action.payload.field] = action.payload.data;
    },
    clearAllChallengesFormFields: (state) => {
      state.id = null;
      state.name = '';
      state.startDate = undefined;
      state.endDate = undefined;
      state.description = '';
      state.image = DEFAULT_CHALLENGE_IMG_CONFIG._1;
      state.challengeType = CHALLENGE_PARTICIPANT_TYPE.INDIVIDUAL;
      state.challengeGroupType = CHALLENGE_CATEGORY_TYPE.LOCATION;
      state.groupIds = [];
      state.isPreDefined = true;
      state.preDefinedChallengeId = 4;
      state.preDefinedChallengeCategoryId = 0;
      state.isMandatory = 1;
      state.coinPerUser = 20;
      state.actionType = ChallengeActionType.UPLOAD_SINGLE_IMAGE;
      state.actionText = "";
    }
  }
});

export const {
  setChallengesFormField,
  clearAllChallengesFormFields
} = challengesFormSlice.actions;

export default challengesFormSlice.reducer;