export const RoutesPath = {
  ALL: '/*',
  DEFAULT: '/',
  LOGOUT: '/logout',
  LOGIN: '/login',
  AUTH_ALL: '/auth/*',
  ERROR_404: '/error/404',
  ERROR_ALL: '/error/*',
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  DETAILS: '/details',
  LEADERBOARD: '/leaderboard',
  DIVISIONS: '/dashboard/divisions',
  DEPARTMENTS: '/dashboard/departments',
  ALL_DEPARTMENTS: '/dashboard/all-departments',
  LOCATIONS: '/dashboard/locations',
  OUR_FOREST: {
    ROOT: '/our-forest',
    ALLOCATE_PROJECT: '/our-forest/allocate-project',
    ADD_PROJECT: '/our-forest/add-new-project',
    EDIT_PROJECT: '/our-forest/edit-project/:projectId',
    PROJECT_UPDATE: '/our-forest/project-update',
  },
  ADD_EXISTING_FOREST: '/add-existing-forest',
  EMPLOYEES: {
    ROOT: '/employees',
    EDIT_EMPLOYEE: '/employees/edit-employee',
    ADD_EMPLOYEE: '/employees/add-employee',
    EMPLOYEES_DETAILS: '/employees/employees-details',
    EMPTY_EMPLOYEE: '/employees/empty-employee', 
    UPLOAD_CSV: '/employees/upload-csv',
  },
  CONFIGURATION: {
    ROOT: '/configuration',
    ADD_UTILITY: '/configuration/add-utility',
    EMPTY_UTILITY: '/configuration/empty-utility', 
    UTILITY: '/configuration/utility',
  },
  ATTRIBUTES: {
    ROOT: '/attributes',
    CREATE_ATTRIBUTES: '/attributes/create-attribute',
    DETAILS: '/attributes/detail',
  },
  QUIZZES: {
    ROOT: '/quizzes',
    QUIZZES_DETAILS: '/quizzes/detail',
  },
  CHALLENGES: {
    ROOT: '/challenges',
    CREATE_CHALLENGES: '/challenges/create-challenge',
    CHALLENGE_DETAILS: '/challenges/detail',
  },
  POLLS: {
    ROOT: '/polls',
    CREATE_POLL: '/polls/create-poll',
    POLL_DETAILS: '/polls/detail',
  },
  MASTER_DATA: '/master-data',
  CUSTOM_TAGS: '/custom-tags',
  CUSTOM_GROUPS: '/custom-groups',
  ORG_REPORTS: {
    ROOT: '/org-reports',
    REPORT: '/report',
  },
  SETTINGS: '/settings',
  PROFILE_EDIT: '/settings/edit-profile',
  CHANGE_PASSWORD: '/settings/change-password',
}
