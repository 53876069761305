/* eslint-disable global-require */
/* eslint no-restricted-syntax: ["error", "FunctionExpression", "WithStatement", "BinaryExpression[operator='in']"] */
/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { 
  useLazyGetPollsListQuery, 
  useDeletePollMutation, 
  useLazyGetCustomGroupsListQuery,
  useLazyGetPollsSummaryQuery
} from '../store/api';
import { 
  useLazyGetDivisionsListQuery,
  useLazyGetDepartmentsListQuery,
  useLazyGetLocationsListQuery
} from '../../dashboard/store/api';
import { RoutesPath } from '../../../routing/RoutesPath';
import { updateNotification } from '../../../store/slices/notifier';
import { RootState, useAppDispatch } from '../../../store/store';
import { NotificationType } from '../../../../_metronic/partials/components/types';

import ModalDeletePoll from './components/modal-delete-poll';
import { CardPolls } from './components/card-polls';
import { EmptyBackgroudCustomGroups } from '../../../../_metronic/assets/images';
import { setActiveDepartmentsList, setActiveDivisionsList, setLiveCustomGroupsList, setActiveLocationsList } from '../../../store/slices/masterList';
import { FilterExistingPolls } from './components/filter-existing-polls';

import './styles.css';
import { HeaderInfoCard } from '../quizzes/components/header-info-card';


type Props = {

}

export function transformPollsListData(inputData:any) {
  const result: any = [];

  (inputData || []).forEach((item: any) => {
    const newItem: any = {
      id: item.id,
      name: item.name,
      startDate: item.startDate,
      endDate: item.endDate,
      question: item.question,
      options: item.answers,
      groupType: item.groupType,
      groupIds: item.groupIds,
      status: item.status,
    };
    result.push(newItem);
  })

  return result;
}

const PollsPage: React.FC<Props> = () => {

   /* ------------------------- REDUX DISPATCHER, SELECTOR --------------------------- */

   const intl = useIntl();
   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const { filterPollType } = useSelector((state: RootState) => state.filters);
   const { activeDepartmentsList, activeDivisionsList, activeLocationsList, liveCustomGroupsList } = useSelector((state: RootState) => state.masterList)

  /* ----------------------------- REACT STATES ------------------------------ */

  const [deleteModalShow, setDeleteModalShow] = useState<boolean>(false);
  const [deletePollId, setDeletePollId] = useState<number>(0);
  const [transformedPollsListData, setTransformedPollsListData] = useState([]);

  /* --------------------------------- RTK QUERY ----------------------------------- */

  const [getPollsList, {
    data: pollsListData, 
    isSuccess: isSuccessGetPolls, 
    isLoading: isLoadingGetPolls,
    isError: isErrorGetPolls,
  }] = useLazyGetPollsListQuery();

  const [deletePoll, {
    isSuccess: isSuccessDeletePoll, 
    isLoading: isLoadingDeletePoll, 
    isError: isErrorDeletePoll
  }] = useDeletePollMutation();

  const [getDivisionsList, {data: divisionsListData}] = useLazyGetDivisionsListQuery()
  const [getDepartmentsList, {data: departmentsListData}] = useLazyGetDepartmentsListQuery()
  const [getLocationsList, {data: locationsListData}] = useLazyGetLocationsListQuery()

  const [getCustomGroupList, {
    data: customGroupsListData, 
    isSuccess: isSuccessGetGroups, 
    isLoading: isLoadingGetGroups,
    isError: isErrorGetGroups,
  }] = useLazyGetCustomGroupsListQuery();

  const [getPollsSummary, {
    data: pollsSummary,
    isSuccess: isSuccessPollsSummary,
    isLoading: isLoadingPollsSummary,
    isError: isErrorPollsSummary
  }] = useLazyGetPollsSummaryQuery();

  /* ---------------------------------- HANDLERS ----------------------------------- */

  const handleDeletePoll = () => {
    // deletePoll({
    //   "id": deletePollId
    // })
    // .then(res => {
    //   getPollsList({})
    //   .catch(err => console.log(err))
    // })
    // .catch(err => console.log(err))
  }

  const handleRefetchPollsList = () => {
    getPollsList({status: filterPollType})
      .catch(err => console.log(err))
  }

  const handleCreatePollsNavigation = () => {
    navigate(RoutesPath.POLLS.CREATE_POLL)
  }

  /* --------------------------- COMPONENT MOUNT - API CALLS ----------------------------- */

  useEffect(() => {
    if (pollsListData) {
      const groupsRes:any = transformPollsListData(pollsListData?.data?.results);
      setTransformedPollsListData(groupsRes);
    }
  }, [pollsListData])

  useEffect(() => {
    getPollsList({status: filterPollType})
    .catch(err => console.log(err))

    getPollsSummary({});
  }, [filterPollType]);

  useEffect(() => {
    if (activeDivisionsList.length === 0) {
      getDivisionsList({isActive: true}).then((res:any)=>{
        const arr: any[] = []
        res?.data.forEach((itm:any) => {
          arr.push(itm)
        })
        dispatch(setActiveDivisionsList(arr))
      })
    }
    if (activeDepartmentsList.length === 0) {
      getDepartmentsList({isActive: true}).then((res:any)=>{
        const arr: any[] = []
        res?.data.forEach((itm:any) => {
          arr.push(itm)
        })
        dispatch(setActiveDepartmentsList(arr))
      })
    }
    if (activeLocationsList.length === 0) {
      getLocationsList({isActive: true}).then((res:any)=>{
        const arr: any[] = []
        res?.data.forEach((itm:any) => {
          arr.push(itm)
        })
        dispatch(setActiveLocationsList(arr))
      })
    }
    if (liveCustomGroupsList.length === 0) {
      getCustomGroupList({}).then((res:any)=>{
        let arr: any[] = Object.values(res?.data?.data?.groups || {});
        arr = arr.filter((item: any) => item.status === "live");
        dispatch(setLiveCustomGroupsList(arr));
      })
    }
  }, []);

  /* ----------------------------- TOAST MESSAGES ------------------------------ */

  useEffect(() => {
    if (isSuccessDeletePoll && !isLoadingDeletePoll) {
      dispatch(
        updateNotification({
          message: 'Poll deleted successfully', 
          type: NotificationType.SUCCESS
        })
      )
    }
  }, [isSuccessDeletePoll])

  useEffect(() => {
    if (isErrorDeletePoll || isErrorGetPolls) {
      dispatch(
        updateNotification({
          message: intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}),
          type: NotificationType.ERROR,
        })
      )
    }
  }, [isErrorDeletePoll, isErrorGetPolls])

  return (
    <div className='blank-page-view py-8 px-12'>
      <div className='d-flex align-items-center justify-content-between mb-8'>
        <h1 className='m-0 page-title-text'>POLLS</h1>
        <button
          type='button'
          // disabled={!loading}
          className='custom-group-category-filter-button m-0 mx-4 px-12 py-2'
          style={{
            borderRadius: '8px',
            fontWeight: '700'
          }}
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
          onClick={handleCreatePollsNavigation}
        >
          CREATE A NEW POLL
        </button>
      </div>
      {/* <div className='mt-2 d-flex align-items-center justify-content-center'>
        <CreatePollsHeroImage width='240px' height="100px" />
      </div> */}
      {/* HEADER CARDS */}
      <div className='d-flex justify-content-between'>
        <HeaderInfoCard   
          label="TOTAL POLLS CONDUCTED"
          value={`${pollsSummary?.data?.totalPolls !== undefined ? pollsSummary?.data?.totalPolls : '--'}`}
          containerStyle={{width: '25%'}}
          />
        <HeaderInfoCard 
          label="ENGAGEMENT RATE %"
          value={`${pollsSummary?.data?.engagementRate !== undefined ? Math.round(Number(pollsSummary?.data?.engagementRate || 0)) : '--'}%`}
          containerStyle={{width: '25%'}}
          />
        <HeaderInfoCard 
          type="white"
          label="TOP POLL"
          value={pollsSummary?.data?.topPoll?.name ? pollsSummary?.data?.topPoll?.name?.toUpperCase() : '--'}
          containerStyle={{width: '25%'}}
          />
        <HeaderInfoCard 
          type="white"
          label="TOTAL EMPLOYEES PARTICIPATED"
          value={pollsSummary?.data?.totalUniqueEmployees !== undefined ? pollsSummary?.data?.totalUniqueEmployees : '--'}
          containerStyle={{width: '25%'}}
          />
      </div>
      <div className='mt-8'>
        <h1 className='page-title-text fs-2 mb-6 mt-6'>EXISTING POLLS</h1>
        <FilterExistingPolls />
        <div className='container-custom-groups-card'>
          {
            transformedPollsListData?.length > 0 && 
            transformedPollsListData?.map((group: any) => (
              <CardPolls 
                key={group.id}
                showDeleteModal={(id: number) => {
                  setDeletePollId(id);
                  setTimeout(() => setDeleteModalShow(true), 200); 
                }}
                groupData={group}
                />
            ))
          }
          {
            isLoadingGetPolls && !isSuccessGetPolls ? (
              <div className='mt-12 d-flex align-items-center justify-content-center' style={{flex: 1}}>
                <h3>Loading...</h3>
              </div>
            ) : (
              transformedPollsListData?.length === 0 ?
              <div className='d-flex justify-content-center align-items-center my-10' style={{flex: 1, flexDirection: 'column'}}>
                <h3>There are no {filterPollType}{filterPollType==="draft"&&"ed"} polls!</h3>
                <EmptyBackgroudCustomGroups height={300} width={400} />
              </div> : null
            )
          }
        </div>
      </div>
      <ModalDeletePoll 
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)} 
        onDelete={handleDeletePoll}
        />
    </div>
  )
}

export default PollsPage;
/* eslint-disable no-nested-ternary */
/* eslint no-restricted-syntax: ["error", "FunctionExpression", "WithStatement", "BinaryExpression[operator='in']"] */
/* eslint-disable global-require */