/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useCallback, useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import '../styles.css'
import {ChartValueTypes} from '../../../../app/modules/dashboard/type'
import { commaSeparatedFormat } from '../../../../app/utils/helpers'
import ModalDownloadChart, {ChartType} from '../modal-download-charts/modal-download-chart'
import { DownloadIcon } from '../../../assets/icons'

const getCopyChartOptions = (chartOptions: ApexOptions, copychartref: any) => {
  const copyChartOptions: ApexOptions = {...chartOptions};

  // if (copyChartOptions?.legend) copyChartOptions.legend.show = false;
  // if (copyChartOptions?.chart?.height) copyChartOptions.chart.height = '250px';

  const copychart = new ApexCharts(copychartref.current, copyChartOptions);
  return copychart;
}

function getChartOptions(_height: number, categories: string[]): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-info')

  return {
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 325,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {},
    legend: {
      show: true,
      markers: {
        width: 20,
        height: 2,
        strokeColor: borderColor,
        strokeWidth: 0,
        fillColors: ['#F28881'],
        offsetX: 0,
        offsetY: 0,
        radius: 0,
        customHTML: () =>
          '<div style="width: 20px;height: 2px;border-top: 2px dashed #F28881;"></div>',
      },
    },
    dataLabels: {
      enabled: false,
    },
    // fill: {
    //   type: 'solid',
    //   opacity: 1,
    // },
    fill: {
      type: 'gradient',
      // opacity: 1,
      gradient: {
        // shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.2,
        // stops: [30, 100, 100]
      }
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: [3, 2],
      colors: ['#F28881'],
      dashArray: [0, 8],
    },
    xaxis: {
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
        formatter: (val) => `${val}`
      },
      crosshairs: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      tickAmount: 4,
      title: {
        text: 'kgCO2',
        style: {
          color: labelColor,
          fontSize: '12px',
        },
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
        formatter: (val) => `${commaSeparatedFormat(parseFloat(val?.toFixed(1)))}`,
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: (val) => `${commaSeparatedFormat(parseFloat(val?.toFixed(1)))}`,
      },
      x: {
        formatter: (val) => `${categories[val-1]} (kgCO2)`,
      },
      marker: {
        show: false,
      },
    },
    colors: ['#F28881'],
    grid: {
      borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: '#F28881',
      strokeWidth: 3,
    },
  }
}

const EmissionTrendsChartForAllMonths: FC<ChartValueTypes> = (props) => {
  const {series, categories, disableAnimation} = props
  const chartref = useRef<HTMLDivElement | null>(null)
  const copychartref = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const labelColor = getCSSVariableValue('--bs-gray-500')

  const [downloadChartModalShow, setDownloadChartModalShow] = useState<boolean>(false);
  const [chartModalData, setChartModalData] = useState<any>({title: "Emission Trends"});

  const refreshMode = useCallback(() => {
    if (!chartref.current || !series) {
      return undefined
    }
    const height = parseInt(getCSS(chartref.current, 'height'), 10)
    const values = getChartOptions(height, categories)
    const options = {
      ...values,
      series,
      xaxis: {...values.xaxis, categories, tickAmount: 5},
      ...(disableAnimation && {
        chart: {
          ...values.chart,
          animations: {
            enabled: false,
          },
        },
      }),
    }

    options.yaxis = {
      ...options.yaxis,
      labels: {
        formatter: (value) => `${value}`,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    }

    const chart = new ApexCharts(chartref.current, options)
    // copy chartOptions for modal download preview
    const copychart = getCopyChartOptions(options, copychartref);
    if (chart) {
      chart.render()
      copychart.render()
    }
    return chart
  }, [series, categories, disableAnimation, downloadChartModalShow])

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartref, mode, refreshMode])

  useEffect(() => {
    document.body.setAttribute('data-kt-app-toolbar-fixed', 'true')
  }, [])

  return (
    <div className='card bordered-grey'>
      {/* begin::Header */}
      <div className='card-header d-flex justify-content-end border-0 py-1 my-0'>
        {/* <h3 className='card-title align-items-center flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Emission Trends</span>
        </h3> */}
        <button 
          type='button' 
          className='remove-btn-styles download-report-btn p-0 mx-0'
          onClick={() => setDownloadChartModalShow(true)}
          // style={{display: 'none'}}
          >
          <DownloadIcon color='#5CADC9'/> 
        </button>
      </div>
      {/* end::Header */}

      <div className='card-body my-0 py-0'>
        <div ref={chartref} id='kt_charts_widget_3_chart' />
      </div>
      <div ref={copychartref} style={{display: 'none'}} />

      <ModalDownloadChart 
        show={downloadChartModalShow} 
        onHide={() => setDownloadChartModalShow(false)}
        chartref={copychartref}
        chartType={ChartType.TREND_LINE}
        data={chartModalData}
        />
    </div>
  )
}

export {EmissionTrendsChartForAllMonths}
