/* eslint-disable prefer-spread */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';

import { ChallengesTitleImage } from '../../../../../_metronic/assets/images';
import { DoneCircularIcon } from '../../../../../_metronic/assets/icons';
import { RoutesPath } from '../../../../routing/RoutesPath';
import { RootState } from '../../../../store/store';
import { pollsFormSlice, setPollsFormField } from '../../../../store/slices/forms/pollsForm';

import CheckboxesPolls from '../components/checkboxes-create-polls';
import "../styles.css";

type PropsType = {
  handleFormNavigation: (navigateTo: "part_one" | "part_two") => void
}

const OptionCountButton = (props: any) => {
  const {onClick, icon} = props;
  return (
    <button
      type="button"
      className="my-0 remove-btn-styles "
      style={{ 
        height: '40px', 
        width: '40px', 
        backgroundColor: "lightgrey", 
        marginLeft: '10px',
        borderRadius: '20%', 
        color: '#666',
        fontSize: '32px',
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      onClick={onClick}
      >
      {icon}
    </button>
  )
}

const OptionInput = (props: any) => {
  const {currentOption, optionCount, setOptionCount, optionValue, setOptionValue} = props;
  if (currentOption > optionCount) return null;

  return (
    <div className='d-flex align-items-center py-0 mb-3'>
      <h6 className='my-0' style={{display: 'inline-block', marginRight: '10px'}}>{["A","B","C","D"][currentOption-1]}.</h6>
      <div className='wrap-custom-default-input-styles' style={{width: '75%'}}>
        <input
          placeholder='Enter poll option'
          className='custom-default-input-styles'
          value={optionValue}
          onChange={(v) => setOptionValue(v.currentTarget.value)}
          />
      </div>
      {
        ([3, 4].includes(currentOption) && optionCount === currentOption) && (
          <OptionCountButton icon="-" onClick={() => setOptionCount((state:number) => state - 1)} />
        )
      }
      {
        ([2, 3].includes(currentOption) && optionCount === currentOption) && (
          <OptionCountButton icon="+" onClick={() => setOptionCount((state:number) => state + 1)} />
        )
      }
    </div>
  )
}

const PartOneCreatePollPage: React.FC<PropsType> = (props) => {

  const {handleFormNavigation} = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pollsForm } = useSelector((state: RootState) => state);

  /* ----------------------------------- STATES ----------------------------------- */

  // OPTIONS VALUES
  const [optionOne, setOptionOne] = useState<string>("");
  const [optionTwo, setOptionTwo] = useState<string>("");
  const [optionThree, setOptionThree] = useState<string>("");
  const [optionFour, setOptionFour] = useState<string>("");
  const [numberOfOptions, setNumberOfOptions] = useState<number>(2);
  // VALIDATION STATES
  const [isValidName, setIsValidName] = useState<boolean>(true);
  const [isValidStartDate, setIsValidStartDate] = useState<boolean>(true);
  const [isValidEndDate, setIsValidEndDate] = useState<boolean>(true);
  const [isValidQuestion, setIsValidQuestion] = useState<boolean>(true);
  const [isValidOptions, setIsValidOptions] = useState<boolean>(true);
  const [isValidOptionOne, setIsValidOptionOne] = useState<boolean>(true);
  const [isValidOptionTwo, setIsValidOptionTwo] = useState<boolean>(true);

  /* ---------------------------------- VALIDATION ---------------------------------- */

  const checkIsValid = () => {
    const validName = pollsForm.name.length > 5;
    const validStartDate = !!pollsForm.startDate;
    const validEndDate = !!pollsForm.endDate;
    const validQuestion = pollsForm.question.length > 10;
    const validOptions = pollsForm.options.length > 1;
    const validOptionOne = optionOne.trim().length > 0;
    const validOptionTwo = optionTwo.trim().length > 0;

    if (!validName) setIsValidName(false);
    if (!validStartDate) setIsValidStartDate(false);
    if (!validEndDate) setIsValidEndDate(false);
    if (!validQuestion) setIsValidQuestion(false);
    if (!validOptions) setIsValidOptions(false);
    if (!validOptionOne) setIsValidOptionOne(false);
    if (!validOptionTwo) setIsValidOptionTwo(false);

    return (validStartDate && validEndDate && validName  && validQuestion && validOptions && validOptionOne && validOptionTwo);
  }

  useEffect(() => {
    if (pollsForm.name.length > 5) setIsValidName(true);
    if (pollsForm.question.length > 10) setIsValidQuestion(true);
    if (pollsForm.startDate) setIsValidStartDate(true);
    if (pollsForm.endDate) setIsValidEndDate(true);
    if (pollsForm.options.length > 1) setIsValidOptions(true);
    if (optionOne.trim().length > 0) setIsValidOptionOne(true);
    if (optionTwo.trim().length > 0) setIsValidOptionTwo(true);
  }, [pollsForm.name, pollsForm.question, pollsForm.startDate, pollsForm.endDate, pollsForm.options, optionOne, optionTwo]);

  /* ------------------------------------ HANDLERS ------------------------------------ */

  useEffect(() => {
    if (pollsForm.options.length) {
      setOptionOne(pollsForm.options[0].answer);
      if (pollsForm.options.length > 1) setOptionTwo(pollsForm.options[1].answer);
      if (pollsForm.options.length > 2) setOptionThree(pollsForm.options[2].answer);
      if (pollsForm.options.length > 3) setOptionFour(pollsForm.options[3].answer);
      setNumberOfOptions(pollsForm.options.length);
    }
  }, [pollsForm.id]);

  useEffect(() => {
    if (optionOne.trim().length > 0 && optionTwo.trim().length > 0) {
      if (optionThree.trim().length > 0 && optionFour.trim().length === 0 && numberOfOptions === 3)
        dispatch(setPollsFormField({field: "options", data: [{option: "A", answer: optionOne}, {option: "B", answer: optionTwo}, {option: "C", answer: optionThree}]}));
      else if (optionThree.trim().length > 0 && optionFour.trim().length > 0 && numberOfOptions === 4)
        dispatch(setPollsFormField({field: "options", data: [{option: "A", answer: optionOne}, {option: "B", answer: optionTwo}, {option: "C", answer: optionThree}, {option: "D", answer: optionFour}]}));
      else 
        dispatch(setPollsFormField({field: "options", data: [{option: "A", answer: optionOne}, {option: "B", answer: optionTwo}]}));
    } else {
      dispatch(setPollsFormField({field: "options", data: []}));
    }
  }, [optionOne, optionTwo, optionThree, optionFour, numberOfOptions]);

  const handleSaveAsDraft = () => {
    navigate(RoutesPath.POLLS.ROOT);
  }

  const handleContinuePartOne = () => {
    if (checkIsValid()) handleFormNavigation("part_two");
  }

  return (
    <div className='mb-10'>

      {/* --------------------------------------- HEADER ------------------------------------------ */}

      <div className='d-flex align-items-center justify-content-center'>
        <ChallengesTitleImage height={80} width={100} className='mt-2' />
        <h2 className='m-0 px-6' style={{fontWeight: '800'}}>CREATE A POLL</h2>
      </div>
      <div className='my-6 d-flex align-items-center justify-content-center'>
        <div className='position-relative'>
          <DoneCircularIcon height={24} width={24} color="#0491BD" />
          <p 
            className='position-absolute' 
            style={{
              fontWeight: '800',
              marginTop: '5px',
              color: '#666666',
              width: 'max-content',
              left: '-40px'
            }}
            >
              Poll description
          </p>
        </div>
        <div className='line-bar' style={{marginLeft: '4px', backgroundColor: "#0491BD"}} />
        <div className='line-bar' style={{marginRight: '4px', backgroundColor: "#D9D9D9"}} />
        <button 
          type="button"
          className="position-relative remove-btn-styles"
          // onClick={() => handleFormNavigation("part_two")}
          style={{cursor: 'default'}}
          >
          <DoneCircularIcon height={24} width={24} color="#D9D9D9" />
          <p 
            className='position-absolute' 
            style={{
              fontWeight: '800',
              marginTop: '5px',
              color: '#666666',
              width: 'max-content',
              left: '-60px'
            }}
            >
            Participant&apos;s information
          </p>
        </button>
      </div>

      {/* --------------------------------------- BODY ------------------------------------------ */} 

      <form className='mt-10 mx-0 col'>

        <div className='row mt-10'>
          {/* POLL NAME  */}
          <div className='col-6 my-8'>
            <h6 className='fs-5'>ENTER NAME</h6>
            <div className='wrap-custom-default-input-styles'>
              <input
                placeholder='Enter poll name'
                className='custom-default-input-styles'
                value={pollsForm.name}
                onChange={(v) => {
                  dispatch(setPollsFormField({field: "name", data: v.currentTarget.value}));
                }}
                />
            </div>
            {
              !isValidName &&
              <p style={{color: 'red', margin: '0px'}}>*Poll name is required (*min 6 chars)</p>
            }
          </div>
          {/* START DATE & END DATE  */}
          <div className='row p-0 m-0'>
            <div className='col-6 m-0 d-flex' style={{flexDirection: 'column'}}>
              <h6 className='fs-5 m-0 mb-2'>SELECT START DATE</h6>
              <DatePicker
                className='polls-datepicker-styles'
                selected={pollsForm.startDate ? new Date(pollsForm.startDate) : undefined} 
                onChange={(date: any) => dispatch(setPollsFormField({field: "startDate", data: date}))}
                minDate={new Date()}
                maxDate={pollsForm.endDate ? new Date(pollsForm.endDate) : undefined}
                placeholderText='DD/MM/YYYY'
                dateFormat='dd/MM/YYYY'
                showMonthDropdown
                showYearDropdown
                // disabled={challengeData?.status && challengeData?.status === 'live'}
                />
              {
                !isValidStartDate &&
                <p style={{color: 'red', margin: '0px'}}>*Start date is required</p>
              }
            </div>

            <div className='col-6 m-0 d-flex' style={{flexDirection: 'column'}}>
              <h6 className='fs-5 m-0 mb-2'>SELECT END DATE</h6>
              <DatePicker
                className='polls-datepicker-styles'
                selected={pollsForm.endDate ? new Date(pollsForm.endDate) : undefined} 
                onChange={(date: any) => dispatch(setPollsFormField({field: "endDate", data: date}))}
                minDate={pollsForm.startDate ? new Date(pollsForm.startDate) : new Date()}
                placeholderText='DD/MM/YYYY'
                dateFormat='dd/MM/YYYY'
                showMonthDropdown
                showYearDropdown
                // disabled={challengeData?.status && challengeData?.status === 'live'}
                />
              {
                !isValidEndDate &&
                <p style={{color: 'red', margin: '0px'}}>*End date is required</p>
              }
            </div>
          </div>
          {/* QUESTION  */}
          <div className='col-12'>
            <h6 className='mt-8 fs-5'>ENTER QUESTION</h6>
            <div className='wrap-custom-default-input-styles'>
              <textarea
                placeholder='Enter poll question'
                className='custom-default-input-styles'
                value={pollsForm.question}
                onChange={(v) => {
                  dispatch(setPollsFormField({field: "question", data: v.currentTarget.value}));
                }}
                />
            </div>
            {
              !isValidQuestion &&
              <p style={{color: 'red', margin: '0px'}}>*Poll question is required (*min 10 chars)</p>
            }
          </div>
          {/* OPTIONS  */}
          <div className='col-12 mt-8 mb-0'>
            <h6 className='fs-5'>ENTER OPTIONS</h6>
            <OptionInput 
              currentOption={1} 
              optionCount={numberOfOptions} 
              setOptionCount={setNumberOfOptions} 
              optionValue={optionOne} 
              setOptionValue={setOptionOne} 
              />
            {
              !isValidOptionOne &&
              <p className='mt-0 mb-2' style={{color: 'red'}}>*Poll option is required</p>
            }
            <OptionInput 
              currentOption={2} 
              optionCount={numberOfOptions} 
              setOptionCount={setNumberOfOptions} 
              optionValue={optionTwo} 
              setOptionValue={setOptionTwo} 
              />
            {
              !isValidOptionTwo &&
              <p className='mt-0 mb-2' style={{color: 'red'}}>*Poll option is required</p>
            }
            <OptionInput 
              currentOption={3} 
              optionCount={numberOfOptions} 
              setOptionCount={setNumberOfOptions} 
              optionValue={optionThree} 
              setOptionValue={setOptionThree} 
              />
            <OptionInput 
              currentOption={4} 
              optionCount={numberOfOptions} 
              setOptionCount={setNumberOfOptions} 
              optionValue={optionFour} 
              setOptionValue={setOptionFour} 
              />
            {/* {
              !isValidOptions &&
              <p style={{color: 'red', margin: '0px'}}>*Poll options are required</p>
            } */}

          </div>
          {/* CHECKBOXES  */}
          <CheckboxesPolls />

        </div>

        <div className='my-8 d-flex align-items-center justify-content-evenly'>
          {/* <button
            type='button'
            // disabled={!loading}
            className='custom-group-category-filter-button m-0 mt-8 px-14'
            style={{
              borderRadius: '8px',
              fontWeight: '700'
            }}
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            onClick={handleSaveAsDraft}
            >
            SAVE AS DRAFT
          </button> */}

          <button
            type='button'
            // disabled={!loading}
            className='custom-group-category-filter-button m-0 mt-8 px-20'
            style={{
              borderRadius: '8px',
              fontWeight: '700'
            }}
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            onClick={handleContinuePartOne}
            >
            CONTINUE
          </button>
        </div>

      </form>

    </div>
  )
}

export default PartOneCreatePollPage;
/* eslint-disable prefer-spread */