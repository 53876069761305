import moment from "moment"
import { useNavigate } from "react-router-dom"

import { PollFilterType } from "../../../../store/slices/filters"
import { EditPenIcon, TrashIcon2 } from "../../../../../_metronic/assets/icons"
import { RoutesPath } from "../../../../routing/RoutesPath"


type groupDataType = {
  id: number
  name: string
  question: string
  startDate: string
  endDate: string | null
  groupIds: number[],
  status: PollFilterType
  participantCount: number
}

type propsType = {
  groupData: groupDataType
  showDeleteModal: Function
}

const RenderPollStatusFlag = (props: any) => {
  const {status} = props;
  let bkgColor = '#A53838';
  let statusText = 'Ended';
  switch (status) {
    case "upcoming":
      bkgColor = '#DCA519';
      statusText = 'Upcoming';
      break;
    case "live":
      bkgColor = '#04BD38';
      statusText = "Live";
      break;
    case "completed":
      bkgColor = '#A53838';
      statusText = 'Completed';
      break;
    case "draft":
      bkgColor = '#84C0D2';
      statusText = 'Draft';
      break;
    default:
      bkgColor = '#A53838';
      statusText = 'Completed';
      break;
  }
  return (
    <div 
      className='m-0'
      style={{
        position: 'absolute',
        padding : '0px 20px',
        borderRadius: "8px 0px 10px 0px",
        backgroundColor: bkgColor,
        color: 'white',
        fontWeight: '700'
      }}
      >
      <p className='m-0 fs-8'>{statusText}</p>
    </div>
  )
}

export const CardPolls = (props: propsType) => {
  const {
    groupData,
    showDeleteModal
  } = props;

  const navigate = useNavigate();

  return (
    <button
      key={groupData.id}
      type="button"
      className="remove-btn-styles bordered-grey card-custom-groups-div"
      style={{
        textAlign: 'left', 
        cursor: groupData.status === 'draft' ? 'default' : 'pointer',
        display: 'flex',
        flexDirection: 'column'
      }}
      onClick={() => {
        if (groupData.status !== 'draft')
          navigate(RoutesPath.POLLS.POLL_DETAILS, {state: groupData})
      }}
      >
      <RenderPollStatusFlag status={groupData.status} />       
      <div className="card-custom-groups-header-div d-flex justify-content-between align-items-center position-relative w-100">
        <div className="d-flex align-items-center w-100" style={{marginLeft: '20px'}}>
          <div style={{maxWidth: groupData.status === "draft" ? '70%' : '85%'}}>
            <h2 className='d-flex fs-3 m-0 bold align-items-center position-relative' style={{color: "#5C5C5C", paddingRight: '10px'}}>
              {groupData.name}
            </h2>
            {/* <p className='fs-6 m-0' style={{color: "#636363"}}>{groupData.participantCount} members</p> */}
          </div>
        </div>
        {
          groupData.status === "draft" ?
          <div>
            <button
              type="button"
              className="remove-btn-styles"
              onClick={() => {
                navigate(RoutesPath.POLLS.CREATE_POLL,{state: groupData})
              }}
              >
              <EditPenIcon 
                color="#84C0D2"
                height={22}
                width={22}
                style={{padding: '2px'}}
                />
            </button>
            {/* {
              ["upcoming", "draft"].includes(groupData.status) &&
              <button
                type="button"
                className="remove-btn-styles"
                style={{marginLeft: '5px'}}
                onClick={() => {
                  setTimeout(showDeleteModal(groupData.id), 200);
                }}
                >
                <TrashIcon2 
                  color="#84C0D2"
                  height={26}
                  width={26}
                  style={{padding: '2px'}}
                  />
              </button>
            } */}
          </div> : 
          null
        }
      </div>
      <div className="card-custom-groups-body-div" style={{marginLeft: "15px"}}>
        <ul className='m-0 mb-2 create-polls-bullet-points' style={{paddingLeft: '0px', overflow: "hidden"}}>
          <li>
            <div className="d-flex mb-2">
              <p className="my-0 mx-2 fs-6 bold" style={{color: "#00556B"}}>•</p>
              <p className='fs-6 m-0'>{moment(groupData.startDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}</p>
              {
                groupData.endDate !== null &&
                <p className='fs-6 m-0 mx-1'>- {moment(groupData.endDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}</p>
              }
            </div>
          </li>
          { 
            groupData.question  &&  (
              <li className='m-0 mb-2 fs-7 d-flex'>
                <p className="my-0 mx-2 fs-6 bold" style={{color: "#00556B"}}>•</p>
                <p className="m-0 fs-6 bold" style={{wordBreak: 'break-word'}}>
                  Question: {`${groupData.question?.slice(0,70)}${groupData.question?.length>70 ? '...' : ''}`}
                </p>
              </li> 
            )
          }
        </ul>
      </div>
    </button>
  )
}