/* eslint-disable global-require */
/* eslint-disable react/no-unstable-nested-components */
import React, {useEffect, useMemo, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import moment from 'moment'
import { DateRangePicker } from 'react-date-range'
import { StaticRange } from 'react-date-range'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import ReactTable from '../../../../_metronic/partials/components/tables/ReactTable'
import { 
  useLazyGetAuditReportsQuery,
  useLazyGenerateAuditReportQuery, 
  useLazyGetCustomTagListQuery
} from '../store/api'
import { RoutesPath } from '../../../routing/RoutesPath';
import { DownloadIcon, CalendarMonthIcon, TrashIcon } from '../../../../_metronic/assets/icons'
import '../styles.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { checkIfAllReportCompleted, downloadAuditReportCSV, transformReportsListData } from '../../../utils/helpers'
import { RootState } from '../../../store/store'
import { OrgTagsDropdown } from './dropdown-org-tags'
import { transformTagListData } from '../custom-tags'
import { ModifySVG } from '../../../../_metronic/helpers/components/ModifySVG'
import { updateNotification } from '../../../store/slices/notifier'
import { NotificationType } from '../../../../_metronic/partials/components/types'
import ModalDeleteAuditReport from './components/modal-delete-report'
import ModalDownloadAuditReport from './components/modal-download-report'

type Props = {

}

const RenderHeaderCellCentered = ({value}: {value:string}) => (
  <div className="d-flex justify-content-center">{value}</div>
)
const RenderGroupName = ({value, data}: {value:string, data: any}) => {
  const navigate = useNavigate()
  return (
    <button
      type="button"
      className="fs-5 remove-btn-styles" 
      style={{color: '#1F7F9D'}}
      onClick={() => {
        navigate(RoutesPath.ORG_REPORTS.REPORT, {state: data})
      }}
      >
      {value}
    </button>
  )
}
const RenderCellCenteredValue = ({value}: {value:string}) => (
  <div className='d-flex justify-content-center' style={{color: '#757575'}}>{value}</div>
)
const RenderActionOptions = (props: any) => {
  const {reportId, showDeleteReportModal, showDownloadAuditReportModal, setReportDataToBeDownloaded, setReportIdToBeDeleted, data} = props;
  return (
    <div className='d-flex justify-content-center flex-shrink-0'>
      <button
        type='button'
        onClick={() => {
          setReportIdToBeDeleted(reportId)
          setTimeout(() => {
            showDeleteReportModal()
          }, 200)
        }}
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
      >
        <ModifySVG className='svg-icon-3'>
          <TrashIcon className='svg-icon svg-icon-3' style={{color: '#C03E35'}} />
        </ModifySVG>
      </button>
      <button
        type='button'
        onClick={() => {
          setReportDataToBeDownloaded(data);
          setTimeout(() => showDownloadAuditReportModal(), 200);
        }}
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
      >
        <ModifySVG className='svg-icon-3'>
          <DownloadIcon className='svg-icon svg-icon-3' style={{color: '#1F7F9D'}} />
        </ModifySVG>
      </button>
    </div>
  )
}
const RenderOnboardingStatus = ({value}: {value:string}) => {
  switch (value) {
    case "pending":
      return (
        <span className='d-flex justify-content-center badge fw-semibold me-1 fs-6' style={{  color: '#C8AD2F' }}>
          Pending
        </span>
      )
    case "error":
      return (
        <span className='d-flex justify-content-center badge fw-semibold me-1 fs-6' style={{  color: '#e64343' }}>
          Failed
        </span>
      )
    case "cancelled": 
      return (
        <span className='d-flex justify-content-center badge fw-semibold me-1 fs-6' style={{  color: '#e64343' }}>
          Failed
        </span>
      )
    default:
      return (
        <span className='d-flex justify-content-center badge fw-semibold me-1 fs-6' style={{  color: '#82C44B' }}>
          Completed
        </span>
      )
  }
}

const OrgReportsPage: React.FC<Props> = () => {
  const {profile} = useSelector((state: RootState) => state.profile)
  const {filterReportsOrgTag} = useSelector((state: RootState) => state.filters)

  const dispatch = useDispatch();
  const intl = useIntl();

  const [isDateRangeVisible, setIsDateRangeVisible] = useState(false)
  const [dateValue, setDateValue] = useState<any>([
    {
      startDate: moment().subtract(1, 'month').startOf('month').toDate(),
      endDate: moment().subtract(1, 'month').endOf('month').toDate(),
      key: 'selection'
    }
  ]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [tagsList, setTagsList] = useState<any[]>([]);
  const [reportsListData, setReportsListData] = useState<any[]>([]);
  const [deleteReportModalShow, setDeleteReportModalShow] = useState(false);
  const [downloadAuditReportShow, setDownloadAuditReportModalShow] = useState<boolean>(false);
  const [reportIdToBeDeleted, setReportIdToBeDeleted] = useState<number>(0);
  const [reportDataToBeDownloaded, setReportDataToBeDownloaded] = useState<any>(null);

  const [getAuditReport, {
    data: orgReportsData,
    isLoading: isLoadingGetAuditReport,
    isSuccess: isSuccessGetAuditReport,
    isError: isErrorGetAuditReport
  }] = useLazyGetAuditReportsQuery();
  const [generateAuditReport, {
    data: auditReportData,
    isLoading: isLoadingGenerateReport,
    isSuccess: isSuccessGenerateReport,
    isError: isErrorGenerateReport
  }] = useLazyGenerateAuditReportQuery();
  const [getCustomTagList, {
    data: customTagListData, 
    isSuccess: isSuccessGetTags, 
    isLoading: isLoadingGetTags,
    isError: isErrorGetTags,
  }] = useLazyGetCustomTagListQuery();

  useEffect(() => {
    setStartDate(moment(dateValue[0].startDate).format('DD/MM/YYYY').toString())
    setEndDate(moment(dateValue[0].endDate).format('DD/MM/YYYY').toString())
  }, [dateValue])

  useEffect(() => {
    if (customTagListData) {
      setTagsList(transformTagListData(customTagListData?.data?.tags) || [])
    }
  }, [customTagListData])

  const staticRanges: StaticRange[] = [
    {
      label: 'Yesterday',
      range: () => ({
        startDate: moment().subtract(1, 'days').startOf('day').toDate(),
        endDate: moment().subtract(1, 'days').endOf('day').toDate(),
      }),
      isSelected: (range) => {
        if (range.startDate === dateValue.startDate && range.endDate === dateValue.endDate) {
          // console.log('yeh')
        }
        return range.startDate === dateValue.startDate && range.endDate === dateValue.endDate
      },
    },
    {
      label: 'Last Week',
      range: () => ({
        startDate: moment().subtract(1, 'week').startOf('week').toDate(),
        endDate: moment().subtract(1, 'week').endOf('week').toDate(),
      }),
      isSelected: (range) => range.startDate === dateValue.startDate && range.endDate === dateValue.endDate,
    },
    {
      label: 'Last Month',
      range: () => ({
        startDate: moment().subtract(1, 'month').startOf('month').toDate(),
        endDate: moment().subtract(1, 'month').endOf('month').toDate(),
      }),
      isSelected: (range) => range.startDate === dateValue.startDate && range.endDate === dateValue.endDate,
    },
  ]

  const headers = useMemo(
    () => [
      {
        Header: "REPORTS",
        accessor: "reportName",
        Cell: ({cell}: {cell: any}) => (
          <RenderGroupName value={cell.row.original.reportName} data={cell.row.original.data} />
        )
      },
      {
        Header: () => (
          <RenderHeaderCellCentered value="PERIOD" />
        ),
        accessor: "period", 
        Cell: ({cell}: {cell: any}) => (
          <RenderCellCenteredValue value={cell.row.original.period} />
        )
      },
      {
        Header: () => (
          <RenderHeaderCellCentered value="GENERATED ON" />
        ),
        accessor: "generatedOn", 
        Cell: ({cell}: {cell: any}) => (
          <RenderCellCenteredValue value={cell.row.original.generatedOn} />
        )
      },
      {
        Header: () => (
          <RenderHeaderCellCentered value="STATUS" />
        ),
        accessor: "status", 
        Cell: ({cell}: {cell: any}) => (
          <RenderOnboardingStatus value={cell.row.original.status} />
        )
      },
      {
        Header: () => (
          <RenderHeaderCellCentered value="ACTION" />
        ),
        accessor: "data", 
        Cell: ({cell}: {cell: any}) => (
          <RenderActionOptions reportId={cell.row.original.id} showDeleteReportModal={() => setDeleteReportModalShow(true)} showDownloadAuditReportModal={() => setDownloadAuditReportModalShow(true)} setReportDataToBeDownloaded={setReportDataToBeDownloaded} setReportIdToBeDeleted={setReportIdToBeDeleted} data={cell.row.original.data}  />
        )
      },
    ],
    []
  )

  const handleGenerateAuditReport = () => {
    if (startDate.length>0 && endDate.length>0 && filterReportsOrgTag.id !== 0) {
      generateAuditReport({
        start_date: moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD').toString(), 
        end_date: moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD').toString(),
        org_tag_id: filterReportsOrgTag.id,
      });
    } else if (startDate.length>0 && endDate.length>0 && filterReportsOrgTag.id === 0) {
      generateAuditReport({
        start_date: moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD').toString(), 
        end_date: moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD').toString(),
      });
    }
  }

  useEffect(() => {
    if (isSuccessGetAuditReport && !isLoadingGetAuditReport) {
      const res = transformReportsListData(orgReportsData.data);
      setReportsListData(res);
    }
  }, [orgReportsData, isSuccessGetAuditReport, isLoadingGetAuditReport])

  // API CALLS
  useEffect(() => {
    getCustomTagList({})
    .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    getAuditReport({})
    .catch(err => console.log(err))
  }, [])

  // pop-ups - toast msg
  useEffect(() => {
    if (isSuccessGenerateReport && !isErrorGenerateReport) {
      dispatch(
        updateNotification({
          message: 'Report generated successfully', 
          type: NotificationType.SUCCESS
        })
      )
      getAuditReport({})
      .catch(err => console.log(err))
    }
  }, [isSuccessGenerateReport])

  useEffect(() => {
    if (isErrorGenerateReport) {
      dispatch(
        updateNotification({
          message: intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}),
          type: NotificationType.ERROR,
        })
      )
    }
  }, [isErrorGenerateReport])

  return (
    <div className='blank-page-view py-8 px-12'>
      <div className='d-flex justify-content-between mb-4'>
        <OrgTagsDropdown tagsList={tagsList} />
        <div className='report-page-header-right'>
          <div className='date-range-picker-wrap mx-4'>
            <button type='button' className='remove-btn-styles download-report-btn' onClick={() => setIsDateRangeVisible((state) => !state)}>
              <CalendarMonthIcon /> <p className='mb-0 px-1'>{startDate} - {endDate}</p>
            </button>
            <div className='date-range-picker-div' style={{display: isDateRangeVisible?'block':'none'}}>
              <DateRangePicker
                onChange={item => setDateValue([item.selection])}
                // showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={dateValue}
                direction="horizontal"
                maxDate={profile?.organization?.isWorkshopOrg ? new Date('2030-12-31') : new Date(moment().subtract(1, 'days').format('YYYY-MM-DD'))}
                inputRanges={[]}
                staticRanges={staticRanges}
              />
              <div className='report-action-btn-wrapper'>
                <button type='button' className='remove-btn-styles report-action-btn' onClick={() => setIsDateRangeVisible(false)}>Cancel</button>
                <button 
                  type='button'
                  className='remove-btn-styles report-action-btn bg-blue' 
                  onClick={() => {
                    setIsDateRangeVisible(false)
                  }}
                  >
                  Ok
                </button>
              </div>
            </div>
          </div>
          <button 
            type='button' 
            className='custom-primary-delete-button py-2' 
            disabled={!checkIfAllReportCompleted(reportsListData) || isLoadingGenerateReport || isLoadingGetAuditReport}
            onClick={() => {
              if (checkIfAllReportCompleted(reportsListData)) {
                handleGenerateAuditReport()
              }
            }}>
            Generate
          </button>
        </div>
      </div>

      <h1 className='page-title-text fs-1' style={{color: '#656565'}}>GENERATED REPORTS</h1>

      <ReactTable
        headerData={headers}
        tableData={reportsListData}
        currentPageIndex={0}
        className="custom-border"
        manualSort
        disableSort
        showPagination={false}
        manualPagination
        message="No data found!"
      />

      <ModalDeleteAuditReport 
        show={deleteReportModalShow} 
        onHide={() => setDeleteReportModalShow(false)} 
        reportId={reportIdToBeDeleted}
        onSubmitSuccess={() => getAuditReport({})}
        />

      <ModalDownloadAuditReport 
        show={downloadAuditReportShow} 
        onHide={() => setDownloadAuditReportModalShow(false)}
        data={reportDataToBeDownloaded}
        />
      
    </div>
  )
}

export default OrgReportsPage
/* eslint-disable global-require */
/* eslint-disable react/no-unstable-nested-components */