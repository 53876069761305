import {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment'
import mixpanel from 'mixpanel-browser'
import {MixedWidget7} from '../../../_metronic/partials/components/chart/MixedWidget7'
import {EmissionTrends} from './EmissionTrends'
import {LocationsList} from './LocationsList'
import {ForestTrends} from './ForestTrends'
import {Employees} from './Employees'
// import { Overview } from '../../../_metronic/partials/components/chart/OverviewChart'
import './Dashboard2.css'
import {
  useLazyGetContributedEmployeesDataQuery,
  useLazyGetOrgEmployeesDataQuery,
  useLazyGetTopActivitiesDataQuery,
  useLazyGetTopEmissionsListQuery,
  useLazyGetTotalEmissionsDataQuery,
  useLazyGetDivisionsListQuery,
  useLazyGetDepartmentsListQuery,
  useLazyGetLocationsListQuery,
  useLazyGetPlansQuery
} from './store/api'
import { PageTitle } from '../../routing/RoutesPageTitle'
import { EVENTS, EVENT_TYPE, PAGES } from '../../analytics/tracker-types'

import {getMMFormat, getYYYYDDMMFormat} from '../../utils/dateFormatter'
import {ActivityEmission, MenuItem} from '../../../_metronic/partials/components/types'
import {Activities} from '../../../_metronic/partials/components/chart/Activities'
import {getLocalStorageItem} from '../../utils/storage'
import {EmissionTrendForAllMonths} from './EmissionTrendForAllMonths'
// import { CardsWidgetWrapper } from '../../../_metronic/partials/components/chart/CardsWidgetWrapper'
import { HeaderWidgetsWrapper } from '../../../_metronic/partials/components/chart/HeaderWidgetsWrapper'
import { setDepartmentsList, setDivisionsList, setLocationsList } from '../../store/slices/masterList'
import { updateExtrapolationFilter } from '../../store/slices/filters'
import { EmptyMixedWidget, EmptyTopContribution } from '../emptyStates/emptyStates'
import { RootState } from '../../store/store'
import HightlightsDashboard from './HightlightsDashboard'
import CurrentPlansWidget from './CurrentPlansWidget'
import { ActivityEmissionStack } from './ActivityEmissionStack'
import { ActivityEmissionStackForAllMonth } from './ActivityEmissionStackForAllMonth'
import SyncFloatingBtn from './component/SyncFloatingBtn'

interface DashboardWrapperProps {
  month: string
}

export function DashboardWrapper2({month}: DashboardWrapperProps) {
  const [monthsFilterOptions, setMonthsFilterOptions] = useState<Array<MenuItem>>([])
  const [timePeriodFilter, setTimePeriodFilter] = useState<MenuItem | undefined>()
  const liveOnDate = new Date(getLocalStorageItem('live-on'))
  
  const dispatch = useDispatch()
  const { filterDate, filterExtrapolation } = useSelector((state: RootState) => state.filters)
  const {profile} = useSelector((state: RootState) => state.profile)

  // const [extrapolationCheck, setExtrapolationCheck] = useState<boolean>(false);

  // update page title
  useEffect(() => {
    document.title = PageTitle.DASHBOARD
    // tracking
    mixpanel.track(EVENT_TYPE.PageView, {'name': PAGES.DashboardPage})
  }, [])

  // useEffect(() => {
  //   setExtrapolationCheck(filterExtrapolation);
  // },[filterExtrapolation])

  // const handleToggleSwitch = () => {
  //   dispatch(updateExtrapolationFilter(!extrapolationCheck))
  // }
  
  // window.alert(liveOnDate)
  const getDateFilters = useCallback(() => ({
      fromDate: filterDate.startDate, 
      toDate: filterDate.endDate
    }), [timePeriodFilter, filterDate])

  // const getDateFilters = useCallback(() => {
  //   if (month === 'All') {
  //     const timePeriodFilterValue = liveOnDate.getUTCMonth().toString()
  //     const currentDate = new Date()
  //     if (timePeriodFilterValue) {
  //       const selectedMonth = parseInt(timePeriodFilterValue, 10)
  //       const selectedYear =
  //         selectedMonth > currentDate.getUTCMonth()
  //           ? currentDate.getUTCFullYear() - 1
  //           : currentDate.getUTCFullYear()

  //       const fromDate = getYYYYDDMMFormat(
  //         new Date(selectedYear, parseInt(timePeriodFilterValue, 10), 1)
  //       )

  //       const toDate = getYYYYDDMMFormat(currentDate)
  //       return {
  //         fromDate,
  //         toDate,
  //       }
  //       // return filterDate
  //     }
  //     return undefined
  //   } else {
  //     const currentDate = new Date()
  //     if (timePeriodFilter) {
  //       const selectedMonth = parseInt(timePeriodFilter.value, 10)
  //       const selectedYear =
  //         selectedMonth > currentDate.getUTCMonth()
  //           ? currentDate.getUTCFullYear() - 1
  //           : currentDate.getUTCFullYear()
  //       const isCurrentMonth = selectedMonth === currentDate.getUTCMonth()

  //       const fromDate = getYYYYDDMMFormat(
  //         new Date(selectedYear, parseInt(timePeriodFilter.value, 10), 1)
  //       )
  //       const toDate = isCurrentMonth
  //         ? getYYYYDDMMFormat(currentDate)
  //         : getYYYYDDMMFormat(new Date(selectedYear, parseInt(timePeriodFilter.value, 10) + 1, 0))
  //       return {
  //         fromDate,
  //         toDate,
  //       }
  //     }
  //     return undefined
  //   }
  // }, [timePeriodFilter])

  const [getTotalEmissions, {data: totalEmissionData}] = useLazyGetTotalEmissionsDataQuery()

  // const [getTotalEmissionsForLastMonth, {data: totalEmissionDataForLastMonth}] =
  // useLazyGetTotalEmissionsDataQuery()

  const [getDivisionsList, {data: divisionsListData}] = useLazyGetDivisionsListQuery()
  const [getDepartmentsList, {data: departmentsListData}] = useLazyGetDepartmentsListQuery()
  const [getLocationsList, {data: locationsListData}] = useLazyGetLocationsListQuery()
  const [getPlans, {data: plansData}] = useLazyGetPlansQuery()

  const [
    getTopEmissionsListDepartments,
    {data: topEmissionsListDepartments, isLoading: isLoadingTopDepartments},
  ] = useLazyGetTopEmissionsListQuery()

  const [
    getTopEmissionsListDivisions,
    {data: topEmissionsListDivisions, isLoading: isLoadingTopDivisions},
  ] = useLazyGetTopEmissionsListQuery()

  const [
    getTopEmissionsListLocations,
    {data: topEmissionsListLocations, isLoading: isLoadingTopLocations},
  ] = useLazyGetTopEmissionsListQuery()

  const [getTopActivitiesList, {data: topActivitiesList, isLoading: isLoadingTopActivities}] =
    useLazyGetTopActivitiesDataQuery()

  // const [getTopActivityForLastMonth, {data: topActivitiesListForLastMonth}] =
  // useLazyGetTopActivitiesDataQuery()

  const [
    getTotalEmployeeOnboardedData,
    {data: totalEmployeeOnboardedData, isLoading: isLoadingTotalEmployeeOnboarded},
  ] = useLazyGetOrgEmployeesDataQuery()

  const [
    getEmployeeContributedData,
    {data: employeeContributedData, isLoading: isLoadingEmployeeContributedData},
  ] = useLazyGetContributedEmployeesDataQuery()

  useEffect(() => {
    getPlans({})
      .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    getDivisionsList({}).then((res:any)=>{
      const arr: any[] = []
      res?.data.forEach((itm:any) => {
        arr.push(itm)
      })
      dispatch(setDivisionsList(arr))
    })
    getDepartmentsList({}).then((res:any)=>{
      const arr: any[] = []
      res?.data.forEach((itm:any) => {
        arr.push(itm)
      })
      dispatch(setDepartmentsList(arr))
    })
    getLocationsList({}).then((res:any)=>{
      const arr: any[] = []
      res?.data.forEach((itm:any) => {
        arr.push(itm)
      })
      dispatch(setLocationsList(arr))
    })
  }, [])

  useEffect(() => {
    let timePeriod: MenuItem | undefined = monthsFilterOptions[0]

    if (month === 'All') {
      timePeriod = {
        label: 'All',
        value: liveOnDate.getUTCMonth().toString(),
      }
    } else {
      timePeriod = monthsFilterOptions.find((option) => option.label === month)
      // setExtrapolationCheck(false)
    }
    if (filterDate.startDate !== '') {
      setTimePeriodFilter(timePeriod)
    }
  }, [monthsFilterOptions, month, filterDate])

  useEffect(() => {
    if (timePeriodFilter) {
      getTotalEmissions({
        ...(timePeriodFilter && {...getDateFilters()}), 
        is_scaled: (month==='All' && filterExtrapolation)
      })
    }
  }, [timePeriodFilter, month, filterExtrapolation])

  useEffect(() => {
    if (timePeriodFilter) {
      getTopEmissionsListDepartments({
        ...(timePeriodFilter && {...getDateFilters()}),
        category: 'department',
        is_scaled: (month==='All' && filterExtrapolation),
      })
    }
  }, [timePeriodFilter, month, filterExtrapolation])

  useEffect(() => {
    if (timePeriodFilter) {
      getTopEmissionsListDivisions({
        ...(timePeriodFilter && {...getDateFilters()}),
        category: 'division',
        is_scaled: (month==='All' && filterExtrapolation),
      })
    }
  }, [timePeriodFilter, month, filterExtrapolation])

  useEffect(() => {
    if (timePeriodFilter) {
      getTopEmissionsListLocations({
        ...(timePeriodFilter && {...getDateFilters()}),
        category: 'location',
        is_scaled: (month==='All' && filterExtrapolation),
      })
    }
  }, [timePeriodFilter, month, filterExtrapolation])

  useEffect(() => {
    if (timePeriodFilter) {
      getTopActivitiesList({
        ...(timePeriodFilter && {...getDateFilters()}),
        is_scaled: (month==='All' && filterExtrapolation),
      })
    }
  }, [timePeriodFilter, month, filterExtrapolation])

  const getDateFiltersForMonth = (selectedMonth: number) => {
    const currentDate = new Date()
    const currentYear = currentDate.getUTCFullYear()

    const selectedYear = selectedMonth > currentDate.getUTCMonth() ? currentYear - 1 : currentYear
    const isCurrentMonth = selectedMonth === currentDate.getUTCMonth()

    const fromDate = getYYYYDDMMFormat(new Date(selectedYear, selectedMonth - 1, 1))

    const toDate = isCurrentMonth
      ? getYYYYDDMMFormat(currentDate)
      : getYYYYDDMMFormat(new Date(selectedYear, selectedMonth + 1, 0))

    return {
      fromDate,
      toDate,
    }
  }
  
  useEffect(() => {
    if (timePeriodFilter) {
      getTotalEmployeeOnboardedData({
        ...(timePeriodFilter && {...getDateFilters()}),
        category: 'division',
      })
      getEmployeeContributedData({
        ...(timePeriodFilter && {...getDateFilters()})
      })
    }
  }, [timePeriodFilter, month])

  const getTimePeriodFilterOptions = () => {
    const currentDate = new Date()
    const currentMonth = currentDate.getUTCMonth()
    const months = Array.from(
      {length: 12},
      (item, i) => new Date(currentDate.getUTCFullYear(), currentMonth - i)
    )
    const monthsOptions: Array<MenuItem> = []
    months.forEach((item) =>
      monthsOptions.push({
        label: getMMFormat(item),
        value: item.getUTCMonth().toString(),
      })
    )
    setMonthsFilterOptions(monthsOptions)
  }

  const [topActivities, setTopActivities] = useState<Array<ActivityEmission>>([])

  useEffect(() => {
    if (topActivitiesList?.top_activities) {
      const sortedData = [...topActivitiesList.top_activities].sort(
        (a, b) => b.emission - a.emission
      )
      const topThree = sortedData.slice(0, 3)
      setTopActivities(topThree)
    }
  }, [topActivitiesList, month])

  const topActivityForSelectedMonth = topActivities[0]

  // const [topActivitiesForPrevMonth, setTopActivitiesForPrevMonth] = useState<Array<ActivityEmission>>([]);

  // useEffect(() => {
  //   if (topActivitiesListForLastMonth?.top_activities) {
  //     const sortedData = [...topActivitiesListForLastMonth.top_activities].sort((a, b) => b.emission - a.emission);
  //     const topThree = sortedData.slice(0, 3);
  //     setTopActivitiesForPrevMonth(topThree);
  //   }
  // }, [topActivitiesListForLastMonth, month]);

  // const topActivityForPrevMonth = topActivitiesForPrevMonth[0]

  useEffect(() => {
    getTimePeriodFilterOptions()
  }, [])

  const employeesOnboarded = totalEmployeeOnboardedData
    ? {total: totalEmployeeOnboardedData.total, onboard: totalEmployeeOnboardedData.onboard}
    : {total: 0, onboard: 0}

  const totalEmissionValue = totalEmissionData?.total_emission
    ? totalEmissionData.total_emission.toLocaleString('en-IN')
    : '0'

  const percentageRecaptured =
    totalEmissionData?.total_emission && totalEmissionData?.total_offset
      ? parseFloat(
          ((totalEmissionData.total_offset / totalEmissionData.total_emission) * 100)?.toFixed(2)
        )
      : 0

  // const topActivityEmissionForLastMonth = topActivityForPrevMonth?.emission ? topActivityForPrevMonth?.emission : 0

  // const totalEmissionForPrev Month = totalEmissionDataForLastMonth?.total_emission ? totalEmissionDataForLastMonth.total_emission: 0
  // const totalRecaptureForPrevMonth = totalEmissionDataForLastMonth?.total_offsets ? totalEmissionDataForLastMonth.total_offsets: 0

  const totalEmissionForSelectedMonth = totalEmissionData?.total_emission
    ? totalEmissionData.total_emission
    : 0
  const totalRecaptureForSelectedMonth = totalEmissionData?.total_offset
    ? totalEmissionData.total_offset
    : 0

  const totalEmployeeContributed = employeeContributedData?.total
    ? employeeContributedData?.total
    : 0

  const totalEmployeeDashboard = employeeContributedData?.total_employees
    ? employeeContributedData?.total_employees
    : 0

  // window.alert(timePeriodFilter?.value) 6
  // window.alert(timePeriodFilter?.label) Jul
  return (
    <div className='m-0 p-4 mb-10 dash-wrap-responsive'>
      <HeaderWidgetsWrapper
        totalEmissionValue={totalEmissionForSelectedMonth}
        totalRecaptureValue={totalRecaptureForSelectedMonth}
        totalEmployeesOnboarded={employeesOnboarded?.onboard}
        totalEmployeesDashboard={totalEmployeeDashboard}
        totalEmployeesContributed={totalEmployeeContributed}
        totalEmployees={profile?.organization?.totalEmployeeCount}
        month={month}
        isPlansAvailable={plansData?.data?.plans?.length>0 || false}
        />
      <div className='wrap2 mt-8'>

        <div className='d-inline'>
          <h3 className='fw-bolder mt-2 mx-2 title d-inline'>EMISSION TRENDS</h3>
        </div>

        <div className='row m-0 mb-8 mt-4'>
          {/* begin::Row3 */}
          <div className='col-md-7 col-lg-7 col-xl-7 col-xxl-7 m-0'>
              {month === 'All' ? (
                <EmissionTrendForAllMonths />
                ) : (
                  timePeriodFilter && <EmissionTrends />
                  )}
          </div>
          {/* <DidYouKnow /> */}
          <div className='col-5'>
            <HightlightsDashboard />
          </div>
          
          {/* end::Row3 */}
        </div>

        <div className='mt-6 mb-3 mx-2'>
          <div className='d-inline'>
            <h3 className='fw-bolder my-2 title d-inline'>ACTIVITY INSIGHTS</h3>
          </div>
        </div>

        <div className='row lkg-5 g-xl-5 mx-0'>
          <div className='col-md-7 col-lg-7 col-xl-7 col-xxl-7 m-0'>
              {month === 'All' ? (
                <ActivityEmissionStackForAllMonth />
                ) : (
                  timePeriodFilter && <ActivityEmissionStack />
                  )}
          </div>

          <div className='col-md-5 col-lg-5 col-xl-5 col-xxl-5'>
            {/* Activities */}
            {
              topActivitiesList ? (
                <Activities
                  className='h-md-35 mb-5'
                  chartColor='danger'
                  chartHeight='300px'
                  title='Activities'
                  desctiption=''
                  data={topActivitiesList?.top_activities}
                />
              ) : <EmptyMixedWidget title='ACTIVITIES' />
            }
          </div>
        </div>

        {/* <div className='mb-3 mx-2'>
          <div className='d-inline'>
            <h3 className='fw-bolder my-2 title d-inline'>TOP CONTRIBUTORS</h3>
            <h4 className='d-inline'> (CO2 EMISSION)</h4>
          </div>
        </div> */}

        {/* <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12'> */}
        {/* begin::Row2 */}
        {
          // (!topEmissionsListDepartments && 
          //   !topEmissionsListLocations && 
          //   !topEmissionsListDivisions &&
          //   !topActivitiesList) ? 

            // <EmptyTopContribution /> : 

        // <div className='row lkg-5 g-xl-5 mx-0'>
        //       {/* begin::Col */}
        //       <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
        //         {/* Divisions */}
        //         {
        //           topEmissionsListDivisions ? (
        //             <MixedWidget7
        //               className='h-md-35 mb-5'
        //               chartColor='danger'
        //               chartHeight='220px'
        //               title='Divisions'
        //               desctiption=''
        //               data={topEmissionsListDivisions}
        //             />
        //           ) : <EmptyMixedWidget title='DIVISIONS' />
        //         }
        //       </div>
        //       {/* end::Col */}
        //       {/* begin::Col */}
        //       <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
        //         {/* Departments */}
        //         {topEmissionsListDepartments ? (
        //           <MixedWidget7
        //             className='h-md-35 mb-5'
        //             chartColor='danger'
        //             chartHeight='220px'
        //             title='Departments'
        //             desctiption=''
        //             data={topEmissionsListDepartments}
        //           />
        //         ) : <EmptyMixedWidget title='DEPARTMENT' />}
        //       </div>
        //       <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4 py-3'>
        //         {/* Activities */}
        //         {/* {
        //           topActivitiesList ? (
        //             <Activities
        //               className='h-md-35 mb-5'
        //               chartColor='danger'
        //               chartHeight='240px'
        //               title='Activities'
        //               desctiption=''
        //               data={topActivitiesList?.top_activities}
        //             />
        //           ) : <EmptyMixedWidget title='ACTIVITIES' />
        //         } */}
        //         {topEmissionsListLocations ? (
        //           <LocationsList data={topEmissionsListLocations} />
        //           ) : <EmptyMixedWidget title='LOCATIONS' />
        //         }
        //       </div>
        //       {/* end::Col */}
        // </div>
        }
        {/* </div> */}

        <div className='mb-3 mx-2 mt-2'>
          <div className='d-inline'>
            <h3 className='fw-bolder my-2 title d-inline'>NET ZERO JOURNEY</h3>
          </div>
        </div>

        <div className='row m-0 mx-2 p-0 mt-4'>
          {/* <div className='col-6'>
            {timePeriodFilter && <Employees totalEmployeesOnboarded={employeesOnboarded} /> }
          </div> */}

          <div className='col-4 m-0 p-0'>
            <CurrentPlansWidget plansData={plansData} />
          </div>
          
          {/* begin::Row4 */}
          <div className='col-8'>
            {/* <div className='mb-2 mt-0'>
              <h3 className='fw-bolder title'>FOREST PLANTATION</h3>
            </div> */}
            {/* begin::Col */}
            {/* <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
              <Overview
                className='h-md-80 mt-2'
                totalEmissionValue={totalEmissionForSelectedMonth}
                totalRecaptureValue={totalRecaptureForSelectedMonth}
                percentageRecaptured={percentageRecaptured}
                chartColor='danger'
                chartHeight='215px'
              />
            </div> */}
            {/* end::Col */}

            {/* begin::Col */}
            {timePeriodFilter && <ForestTrends selectedMonth={timePeriodFilter}  />}
            {/* end::Col */}
          </div>
          {/* end::Row4 */}
        </div>
        {/* WORKSHOP HANDLING - SYNCINIG */}
        {
          (profile?.organization?.isWorkshopOrg) ? (
            <SyncFloatingBtn />
          ) : null 
        }
        
      </div>
    </div>
  )
}
