/* eslint-disable global-require */
import React, {useEffect, useState} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import moment from 'moment'

import { RoutesPath } from '../../../routing/RoutesPath';
import { DownloadIcon, CalendarMonthIcon } from '../../../../_metronic/assets/icons';
import { downloadAuditReportCSV, tagsDarkColorShade } from '../../../utils/helpers'

import AccordionOrgReport from './components/accordion-org-report';
import ModalDownloadAuditReport from './components/modal-download-report'

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../styles.css';

const AuditableReportsPage: React.FC<any> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [downloadAuditReportShow, setDownloadAuditReportModalShow] = useState<boolean>(false);
  const [auditReportData, setAuditReportData] = useState<any>({
    travel: null,
    utilities: null,
    metaData: null,
  });
  
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  useEffect(() => {
    setStartDate(moment(auditReportData.metaData?.fromDate).format('DD/MM/YYYY').toString())
    setEndDate(moment(auditReportData.metaData?.toDate).format('DD/MM/YYYY').toString())
  }, [auditReportData])

  useEffect(() => {
    if (location.state) {
      const data:any = location.state;
      setAuditReportData({
        travel: data.travel || null,
        utilities: data.utilities || null,
        metaData: data.metaData || null
      });
    }
  }, [location])

  return (
    <div className='blank-page-view py-8 px-12'>
      <div className='d-flex justify-content-between mb-4'>
        <div className='back-header-btn'>
          <button 
            type='button' 
            className='p-2 back-left-arrow-btn-wrap'
            onClick={()=>{navigate(RoutesPath.ORG_REPORTS.ROOT)}}
            >
            <div className='back-left-arrow-btn' /> 
          </button>
          <h6 className='m-0 mx-4 fs-4 font-weight-normal'><u>Enterprise Report</u></h6>
        </div>
        <div className='report-page-header-right'>
          <div className='date-range-picker-wrap'>
            <div className='download-report-btn' style={{color: 'grey'}}>
              <CalendarMonthIcon /> <p className='mb-0 px-1'>{startDate} - {endDate}</p>
            </div>
          </div>
          <button 
            type='button' 
            className='remove-btn-styles download-report-btn' 
            onClick={() => {setDownloadAuditReportModalShow(true)}}>
            <DownloadIcon /> <p className='mb-0 px-1'>Download report</p>
          </button>
        </div>
      </div>
      <div className='org-tag-reports-title-container'>
        <div className='d-flex align-items-center'>
          <h1 className='page-title-text mx-6 my-0 p-0'>{auditReportData.metaData?.orgTag ? auditReportData.metaData?.orgTag?.name : "Enterprise Report"}</h1>
          {
            auditReportData.metaData?.orgTag && (
              <div 
                className='custom-group-display-color-card px-4 py-2' 
                style={{
                  backgroundColor: auditReportData.metaData?.orgTag?.colorHexCode, 
                  borderColor: tagsDarkColorShade[auditReportData.metaData?.orgTag?.colorHexCode],
                  color: tagsDarkColorShade[auditReportData.metaData?.orgTag?.colorHexCode],
                  padding: '1px 10px',
                  fontWeight: '600',
                  fontSize: '16px',
                }}
                >
                {auditReportData.metaData?.orgTag?.abbreviationName}
              </div>
            )
          }
        </div>
      </div>
      {/* TABLE - ACCORDION  */}
      <AccordionOrgReport reportData={auditReportData} />

      <ModalDownloadAuditReport 
        show={downloadAuditReportShow} 
        onHide={() => setDownloadAuditReportModalShow(false)}
        data={auditReportData}
        />

    </div>
  )
}

export default AuditableReportsPage
/* eslint-disable global-require */