/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import { GoogleMap, useJsApiLoader, Marker, Polygon } from '@react-google-maps/api';
import { Library } from '@googlemaps/js-api-loader';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { ForestDetailIndividual } from './types';
import Timeline from './Timeline';
import { commaSeparatedFormat } from '../../utils/helpers';

type LatLngType = {
  lat: number
  lng: number
}

const _places:Library = 'places';
const _geometry:Library = 'geometry';
const _drawing:Library = 'drawing';
const _maps:Library = 'maps';
const _libArray:Library[] = [_maps, _geometry, _drawing];

function ProjectUpdates() {
  const location = useLocation();
  const forest = (location.state as ForestDetailIndividual);
  const startYear = new Date(forest.plantation_start_date).getUTCFullYear()
  const [coordinatesArray, setCoordinatesArray] = useState<any[]>([])
  const [center, setCenter] = useState<LatLngType | null>(null)
  const [map, setMap] = useState<any>(null)
  
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || '', 
    // libraries: _libArray
  })

  useEffect(() => {
    if (forest) {
      setCoordinatesArray(forest.forest_boundries.map(itemArray => JSON.parse(itemArray.boundry).map((item:any) => ({ lng: item[0], lat: item[1]}))));
    }
  }, [forest])

  useEffect(() => {
    if (coordinatesArray.length>0) {
      setCenter({
        lat:  coordinatesArray[0][0].lat,
        lng: coordinatesArray[0][0].lng
      })
    }
  }, [coordinatesArray])

  const onLoad = React.useCallback((tmap:any) => {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    if (forest && center) {
      const bounds = new window.google.maps.LatLngBounds(center);
      tmap.fitBounds(bounds);
  
      setMap(tmap)
    }
  }, [forest])

  const onUnmount = React.useCallback((tmap:any) => {
    setMap(null)
  }, [])

  const containerStyle = {
    width: '100%',
    height: '180px'
  }
  
  
  return (
    <>
    <Breadcrumb className='mt-10'>
      <Breadcrumb.Item href="/our-forest">Our Forest</Breadcrumb.Item>
      <Breadcrumb.Item active>{forest?.name || `Mynzo-Forest ${forest?.project_id}`}</Breadcrumb.Item>
    </Breadcrumb>
    <div className='d-flex flex-row justify-content-between flex-1 bg-white pt-10 p-20'>
      <div className='card col-7 forest-detail-card-sticky'>
        <div className='position-relative col-12 height-50'>
          <img
            className='custom-updates-card'
            src='https://mynzo.s3.ap-south-1.amazonaws.com/images/forest.svg'
            alt='Cimg'
          />
        <div className='overlay-div'/>
        </div>
        <div className='card-body custom-border custom-height-35 position-relative'>
          <h5 className='card-title card-overlay-text'>{forest?.name || `Mynzo-Forest ${forest?.project_id}`}<span className='fs-7 mx-4'>
          {Number(forest.area).toFixed(2)} Acres allocated
          </span></h5>
          <div className='d-flex align-items-center  flex-column'>
            <div className='d-flex justify-content-between w-100 mb-2'>
              <div>
                <span className='fw-bold fs-5' style={{color: '#0491BD'}}>
                  {commaSeparatedFormat(forest.area_planted?.toFixed(2))}
                </span>
                <span className='text-muted-dark'>
                  {' '}
                  / {commaSeparatedFormat(Number(forest.area).toFixed(2))} Acres planted
                </span>
              </div>
            </div>
            <div className='h-10px mx-3 mt-1 w-100 bg-light mb-3'>
              <div
                className='bg-progress-green h-10px'
                role='progressbar'
                style={{
                  width: `${Math.round((forest.area_planted/ forest.area) * 100)}%`,
                }}
              />
            </div>
          </div>
          {/** --------- */}
          <div className=' d-flex flex-row justify-content-between'>
            <p style={{color: '#0491BD'}}>{commaSeparatedFormat(forest.recapture?.toFixed(2))} kgCO2 recaptured</p>
            <p>
              <span className='text-muted-dark'>
                {Math.round((forest.area_planted/ forest.area) * 100)}%
              </span>
              <span className='text-muted-dark'> Planted</span>
            </p>
          </div>
          <div className='custom-divider' />
          <div className='mt-2 flex-column d-flex justify-content-between'>
            <div>
              <h5 className='sub-heading'>Location</h5>
              <h6>
                {forest.district} , {forest.villege}
              </h6>
            </div>
            
            
            <div style={{ height: 180, width: '100%', borderRadius:5 }}>
              {
                (isLoaded && center) ? (
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    options={{
                      streetViewControl: false,
                      mapTypeControl: false,
                      clickableIcons:false,
                      zoomControl:true,
                      fullscreenControl:true,
                      mapTypeId: 'hybrid',
                      zoom: 10, 
                      maxZoom: 20, 
                      minZoom: 4, 
                    }}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    >
                    {  
                      coordinatesArray?.map(
                        array => 
                        <>
                          <Polygon
                            paths={array}
                            options={{
                              strokeColor:'#54ffff',
                              fillColor:'rgba(0, 0, 0, 0)',
                              strokeWeight:2,
                              zIndex:1,
                              // accessibilityLabel='Forest'
                            }}
                            />
                          <Marker
                            position={{
                              lat: array[0].lat, 
                              lng: array[0].lng
                            }}
                            />
                      </>
                      )
                    }
                  </GoogleMap>
                ) : (
                  <h4>
                    Fetching Map..
                  </h4>
                ) 
              }
            </div>
          
          </div>
        </div>
      </div>
      <div className='card custom-border col-4 px-5 pt-5 position-relative forest-logs-wrapper'>
        <h3 className='px-15'>Recent Updates</h3>
        <Timeline ids={forest.forest_ids} />
      </div>
      
    </div>
  </>
  )
}

export default ProjectUpdates
