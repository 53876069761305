import {useIntl} from 'react-intl'
import {
  DashboardTabIcon, 
  DivisionsTabIcon, 
  DepartmentTabIcon,
  LeaderboardTabIcon,
  LocationTabIcon, 
  EmployeeTabIcon, 
  ForestTreeIcon, 
  SettingsTabIcon, 
  ConfigurationTabIcon, 
  ReportsTabIcon,
  CustomGroupTabIcon,
  CustomTagTabIcon,
  AttributesTabIcon,
  ChallengesTabIcon,
  QuizzesTabIcon,
  PollsTabIcon,
} from '../../../assets/icons'
import {ModifySVG} from '../../../helpers/components/ModifySVG'
import {AsideMenuItem} from './AsideMenuItem'
import { RoutesPath } from '../../../../app/routing/RoutesPath'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <hr className='aside-menu-hr mb-6' />

      <h6 className='aside-menu-sec-title'>ANALYTICS</h6>

      <AsideMenuItem
        to={RoutesPath.DASHBOARD}
        MenuIcon={
          <ModifySVG className='svg-icon-2'>
            <DashboardTabIcon />
          </ModifySVG>
        }
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
      /> 

      <div style={{paddingLeft: 30}}>
        <AsideMenuItem
          to={RoutesPath.DETAILS}
          MenuIcon={
            <ModifySVG className='svg-icon-2'>
              <DepartmentTabIcon />
            </ModifySVG>
          }
          title={intl.formatMessage({id: 'MENU.DASHBOARD.DETAILS'})}
        /> 
        <AsideMenuItem
          to={RoutesPath.LEADERBOARD}
          MenuIcon={
            <ModifySVG className='svg-icon-2'>
              <LeaderboardTabIcon />
            </ModifySVG>
          }
          title={intl.formatMessage({id: 'MENU.DASHBOARD.LEADERBOARD'})}
        /> 
        {/* <AsideMenuItem
          to={RoutesPath.DIVISIONS}
          MenuIcon={
            <ModifySVG className='svg-icon-2'>
              <DivisionsTabIcon />
            </ModifySVG>
          }
          title={intl.formatMessage({id: 'MENU.DASHBOARD.DIVISIONS'})}
        /> 
        <AsideMenuItem
          to={RoutesPath.DEPARTMENTS}
          MenuIcon={
            <ModifySVG className='svg-icon-2'>
              <DepartmentTabIcon />
            </ModifySVG>
          }
          title={intl.formatMessage({id: 'MENU.DASHBOARD.DEPARTMENTS'})}
        /> 
        <AsideMenuItem
          to={RoutesPath.LOCATIONS}
          MenuIcon={
            <ModifySVG className='svg-icon-2'>
              <LocationTabIcon />
            </ModifySVG>
          }
          title={intl.formatMessage({id: 'MENU.DASHBOARD.LOCATIONS'})}
        />  */}
      </div>

      <hr className='aside-menu-hr mt-6 mb-6 ' />

      <AsideMenuItem
        to={RoutesPath.OUR_FOREST.ROOT}
        MenuIcon={
          <ModifySVG className='svg-icon-2'>
            <ForestTreeIcon />
          </ModifySVG>
        }
        title={intl.formatMessage({id: 'MENU.OUR_FOREST'})}
      /> 

      <AsideMenuItem
        to={RoutesPath.EMPLOYEES.ROOT}
        MenuIcon={
          <ModifySVG className='svg-icon-2'>
            <EmployeeTabIcon />
          </ModifySVG>
        }
        title={intl.formatMessage({id: 'MENU.EMPLOYEES'})}
      />  

      <hr className='aside-menu-hr mt-6 mb-6 ' />

      <h6 className='aside-menu-sec-title'>CUSTOMIZATION</h6>

      <AsideMenuItem
        to={RoutesPath.ATTRIBUTES.ROOT}
        MenuIcon={
          <ModifySVG className='svg-icon-2'>
            <AttributesTabIcon />
          </ModifySVG>
        }
        title={intl.formatMessage({id: 'MENU.ATTRIBUTES_TAB'})}
        />

      <AsideMenuItem
        to={RoutesPath.CHALLENGES.ROOT}
        MenuIcon={
          <ModifySVG className='svg-icon-2'>
            <ChallengesTabIcon />
          </ModifySVG>
        }
        title={intl.formatMessage({id: 'MENU.CHALLENGES'})}
      />

      <AsideMenuItem
        to={RoutesPath.QUIZZES.ROOT}
        MenuIcon={
          <ModifySVG className='svg-icon-2'>
            <QuizzesTabIcon />
          </ModifySVG>
        }
        title={intl.formatMessage({id: 'MENU.QUIZZES'})}
      />

      <AsideMenuItem
        to={RoutesPath.POLLS.ROOT}
        MenuIcon={
          <ModifySVG className='svg-icon-2'>
            <PollsTabIcon />
          </ModifySVG>
        }
        title={intl.formatMessage({id: 'MENU.POLLS'})}
      />

      <AsideMenuItem
        to={RoutesPath.CONFIGURATION.ROOT}
        MenuIcon={
          <ModifySVG className='svg-icon-2'>
            <ConfigurationTabIcon />
          </ModifySVG>
        }
        title={intl.formatMessage({id: 'MENU.ADD_UTILITY'})}
      />

      <AsideMenuItem
        to={RoutesPath.CUSTOM_TAGS}
        MenuIcon={
          <ModifySVG className='svg-icon-2'>
            <CustomTagTabIcon />
          </ModifySVG>
        }
        title={intl.formatMessage({id: 'MENU.CUSTOM_TAGS'})}
      />

      <AsideMenuItem
        to={RoutesPath.CUSTOM_GROUPS}
        MenuIcon={
          <ModifySVG className='svg-icon-2'>
            <CustomGroupTabIcon />
          </ModifySVG>
        }
        title={intl.formatMessage({id: 'MENU.CUSTOM_GROUPS'})}
      />

      <AsideMenuItem
        to={RoutesPath.ORG_REPORTS.ROOT}
        MenuIcon={
          <ModifySVG className='svg-icon-2'>
            <ReportsTabIcon />
          </ModifySVG>
        }
        title={intl.formatMessage({id: 'MENU.REPORTS'})}
      />

      <AsideMenuItem
        to={RoutesPath.MASTER_DATA}
        MenuIcon={
          <ModifySVG className='svg-icon-2'>
            <DepartmentTabIcon />
          </ModifySVG>
        }
        title={intl.formatMessage({id: 'MENU.MASTER_DATA'})}
      />

      <hr className='aside-menu-hr mt-6 mb-6 ' />

      <h6 className='aside-menu-sec-title'>OTHERS</h6>

      <AsideMenuItem
        to={RoutesPath.SETTINGS}
        MenuIcon={
          <ModifySVG className='svg-icon-2'>
            <SettingsTabIcon />
          </ModifySVG>
        }
        title={intl.formatMessage({id: 'MENU.SETTINGS'})}
      />
    </>
  )
}
