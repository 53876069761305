/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useCallback, useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {useSelector} from 'react-redux'
import { getCSS, getCSSVariableValue } from '../../../_metronic/assets/ts/_utils'
import { useThemeMode } from '../../../_metronic/partials'
// import '../../../_metronic/partials/components/styles.css'
import { DateRangeType } from '../../../_metronic/partials/components/types'
import {
  useLazyGetEmissionTrendsQuery,
} from './store/api'
import { getAllDatesOfCurrentMonth, getYYYYDDMMFormat } from '../../utils/dateFormatter'

import { RootState } from '../../store/store'
import { commaSeparatedFormat } from '../../utils/helpers'
import { DownloadIcon } from '../../../_metronic/assets/icons'
import ModalDownloadChart, { ChartType } from '../../../_metronic/partials/components/modal-download-charts/modal-download-chart'

const getCopyChartOptions = (chartOptions: ApexOptions, copychartref: any) => {
  const copyChartOptions: ApexOptions = {...chartOptions};

  // if (copyChartOptions?.legend) copyChartOptions.legend.show = false;
  // if (copyChartOptions?.chart?.height) copyChartOptions.chart.height = '250px';

  const copychart = new ApexCharts(copychartref.current, copyChartOptions);
  return copychart;
}

function getChartOptions(
  _height: number, 
  ): ApexOptions {

  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-info')
  const ht = _height

  return {
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: 310,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    legend: {
      show: true,
      onItemClick: {
        toggleDataSeries: false,
      },
      // onItemHover: {
      //   highlightDataSeries: false,
      // },
      markers: {
        width: 15,
        height: 15,
        strokeColor: borderColor,
        strokeWidth: 0,
        offsetX: 0,
        offsetY: 0,
        radius: 0,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      colors: ['#1b74cf', '#4489cf', '#85C0FE', '#BBDCFF', '#BCC8D4'],
      opacity: 1,
    },
    xaxis: {
      axisBorder: {
        show: false,
        color: '#d8d8d8',
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
      },
      tooltip: {
        enabled: false,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      tickAmount: 4,
      title: {
        text: 'kgCO2',
        style: {
          color: labelColor,
          fontSize: '12px',
        },
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    tooltip: {
      shared: true, 
      intersect: false,
      x: {
        formatter: (val) => `${val} (kgCO2)`
      }
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    colors: ['#1b74cf', '#4489cf', '#85C0FE', '#BBDCFF', '#BCC8D4'],
    grid: {
      borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const ActivityEmissionStack: FC<any> = (props) => {
  const chartref = useRef<HTMLDivElement | null>(null)
  const copychartref = useRef<HTMLDivElement | null>(null)  
  const {mode} = useThemeMode()
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const {filterDate, filterMonth} = useSelector((state: RootState) => state.filters)

  const [getTotalEmission, {data: totalEmissionData}] = useLazyGetEmissionTrendsQuery()

  // state
  const [categories, setCategories] = useState<string[]>([])
  const [downloadChartModalShow, setDownloadChartModalShow] = useState<boolean>(false);
  const [chartModalData, setChartModalData] = useState<any>({title: "Emission By Activity"});

  // const setInitialSelectedDate = (month: number) => {
  //   const currentDate = new Date()
  //   const selectedYear =
  //     month > currentDate.getUTCMonth() ? currentDate.getUTCFullYear() - 1 : currentDate.getUTCFullYear()
  //   let endDay
  //   if (month === currentDate.getUTCMonth()) {
  //     endDay = new Date(selectedYear, month, currentDate.getUTCDate())

  //     // let newComparedMonth = new Date(selectedYear,month+1,currentDate.getUTCDate())
  //     // setSelectedCompareToMonth(newComparedMonth)
  //   } else {
  //     endDay = new Date(selectedYear, month + 1, 0)
  //   }
  // }

  // useEffect(() => {
  //   const month = filterMonth

  //   const monthIndex = months.findIndex((itm) => itm.toLowerCase() === month.toLowerCase())

  //   setInitialSelectedDate(monthIndex)
  // }, [filterMonth])

  useEffect(() => {
    if (filterDate.startDate !== '') {
      setCategories(getAllDatesOfCurrentMonth(filterDate.startDate))

      const reqObject1 = {
        fromDate: filterDate.startDate,
        toDate: filterDate.endDate,
        group_activities: 'true',
      }
      getTotalEmission(reqObject1)
    }
  }, [filterDate])

  interface SeriesData {
    name: string;
    data: number[];
  }

  const series: SeriesData[] = [{
    name: 'Home',
    data: new Array(categories.length).fill(0)
  }, {
    name: 'Office',
    data: new Array(categories.length).fill(0)
  }, {
    name: 'Commute',
    data: new Array(categories.length).fill(0)
  }, {
    name: 'Other',
    data: new Array(categories.length).fill(0)
  }, {
    name: 'Flight',
    data: new Array(categories.length).fill(0)
  }]

  totalEmissionData?.forEach(dataPoint => {
    const indexPoint = new Date(dataPoint.dates).getUTCDate() - 1;
    dataPoint?.activities?.forEach((activity:any) => {
      const entryIdx = series.findIndex(act => act.name === activity.name)
      series[entryIdx].data[indexPoint] = activity.emission;
    })
  })

  const refreshMode = useCallback(() => {
    if (!chartref.current) {
      return undefined
    }
    const height = parseInt(getCSS(chartref.current, 'height'), 10)
    const values = getChartOptions(height)
    const options = {
      ...values,
      series,
      xaxis: {...values.xaxis, categories, tickAmount: 5},
      ...({
        chart: {
          ...values.chart,
          animations: {
            enabled: false,
          },
        },
      }),
    }

    options.yaxis = {
      ...options.yaxis,
      labels: {
        formatter: (value) => `${commaSeparatedFormat(parseFloat(value?.toFixed(1)))}`,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    }

    const chart = new ApexCharts(chartref.current, options)
    // copy chartOptions for modal download preview
    const copychart = getCopyChartOptions(options, copychartref);
    if (chart) {
      chart.render()
      copychart.render()
    }
    return chart
  }, [series, categories, downloadChartModalShow])

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartref, mode, refreshMode])

  return (
    <div className='mt-8 bordered-grey'>
      {/* begin::Header */}
      <div className='card-header d-flex justify-content-between border-0 px-4 pt-3'>
        <h3 className='m-0'>EMISSION BY ACTIVITY</h3>
        <button 
          type='button' 
          className='remove-btn-styles download-report-btn p-0 mx-0'
          onClick={() => setDownloadChartModalShow(true)}
          // style={{display: 'none'}}
          >
          <DownloadIcon color='#5CADC9'/> 
        </button>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body pt-4'>
        {/* begin::Chart */}
        <div ref={chartref} id='kt_charts_widget_3_chart' />
        {/* end::Chart */}
      </div>
      {/* end::Body */}
      <div ref={copychartref} style={{display: 'none'}} />

      <ModalDownloadChart 
        show={downloadChartModalShow} 
        onHide={() => setDownloadChartModalShow(false)}
        chartref={copychartref}
        chartType={ChartType.ACTIVITIES_BAR}
        data={chartModalData}
        />
    </div>
  )
}

export {ActivityEmissionStack}
