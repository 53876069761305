/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import ApexCharts, {ApexOptions} from 'apexcharts'
import ProgressBar from 'react-bootstrap/ProgressBar';
import { RoutesPath } from '../../../../app/routing/RoutesPath' 
import OpenIcon from '../../../../assets/OpenIcon.svg'
import {EmissionDataType} from '../types'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import { commaSeparatedFormat } from '../../../../app/utils/helpers'
import { DownloadIcon } from '../../../assets/icons';
import ModalDownloadChart, { ChartType } from '../modal-download-charts/modal-download-chart';


type Props = {
  className: string
  title: string
  desctiption: string
  data: Array<EmissionDataType> | []
}

const Chart = ({data}: any) => (
  <div className='card-body pt-4 mb-3'>
    {data.topThreeItems.map((item:any,index:number)=>{
      const config = data.getConfig(item);

      return(
        <div key={`${item.emission}${item.percentage_contribution}`} className='flex-1 mx-4 mt-7'>
            <div className='d-flex align-items-center justify-content-between flex-1'>
            <p className='category-location-heading'>{config.heading}</p>
            <p className='category-location-value'>{commaSeparatedFormat(Math.round(item.emission))}<span style={{color:"rgba(141, 141, 141, 1)"}}> kgCO2</span></p>
            </div>
            
        <div style={{backgroundColor:config.bgColor, borderRadius: 8}} className='h-10px mt-1 w-100 mb-3 '>
        <div
          className='bg-progress-blue h-10px'
          role='progressbar'
          style={{
            width: `${item.percentage_contribution}%`,
            borderRadius: 8,
            backgroundColor: config.color
          }}
        />
      </div>
        </div>
    )})}
  </div>
)

const TopCategoryEmission: React.FC<Props> = ({
  className,
  title,
  desctiption,
  data,
}) => {
  const {mode} = useThemeMode()

  const navigate = useNavigate();

  const [downloadChartModalShow, setDownloadChartModalShow] = useState<boolean>(false);
  
  const sortedData = [...data].sort((a, b) => b.emission - a.emission)
  const topThreeItems = sortedData.slice(0, 4)

  const getConfig = (item: EmissionDataType) => {
    if(title === 'Departments'){
      return {
        heading: item?.department,
        color: 'rgba(255, 168, 0, 1)',
        bgColor: 'rgba(255, 236, 199, 1)'
      }
    }
    if(title === 'Divisions'){
      return {
        heading: item?.location,
        color: 'rgba(27, 197, 189, 1)',
        bgColor: 'rgba(208, 255, 253, 1)'
      }
    }if(title === 'Locations'){
      return {
        heading: item?.location,
        color: 'rgba(54, 153, 255, 1)',
        bgColor: 'rgba(204, 229, 255, 1)'
      }
    }
    return {
      heading: '',
      color: '',
      bgColor: 'rgba(255, 168, 0, 1)'
    };
  }

  return (
    <div className='card mb-0 mb-xl-8 pt-2 bordered-grey'>
      {/* begin::Beader */}
      <div className='card-header d-flex align-items-center border-0 pt-2 mb-1'>
        <h3 className='card-title align-items-center flex-row mb-0'>
          <span className='card-label fw-bold fs-3'>BY {title.toUpperCase()}</span>
        </h3>
        <button 
          type='button' 
          className='remove-btn-styles download-report-btn p-1'
          onClick={() => setDownloadChartModalShow(true)}
          // style={{display: 'none'}}
          >
          <DownloadIcon color='#5CADC9'/> 
        </button>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <Chart data={{topThreeItems, getConfig}} />
      {/* end::Body */}
      <ModalDownloadChart 
        show={downloadChartModalShow} 
        onHide={() => setDownloadChartModalShow(false)}
        chartref={null}
        chartType={ChartType.TOP_CATEGORIES}
        data={{title: `Emission By ${title}`, data: {topThreeItems, getConfig}}}
        ChartComponent={Chart}
        />
    </div>
  )
}

export {TopCategoryEmission}
