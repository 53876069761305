import { createSlice } from "@reduxjs/toolkit";
import { 
  POLL_CATEGORY_TYPE, 
  PollCategoryType, 
} from "../../../modules/configuration/polls/types";

type FORM_FIELDS = 
  "id" |
  "name" |
  "startDate" |
  "endDate" |
  "question" |
  "options" |
  "pollGroupType" |
  "groupIds" |
  "isMandatory" |
  "isPublishReady" |
  "isResultVisible"

export type PollOptionsType = {
  option: "A"| "B" | "C" | "D",
  answer: string,
}

export type FormType = {
  id: number | null,
  name: string,
  startDate?: string,
  endDate?: string,
  question: string,
  options: PollOptionsType[],
  pollGroupType: PollCategoryType,
  groupIds: number[],
  isMandatory: number,
  isPublishReady: number,
  isResultVisible: number,
}

export type SetFormActionType = {
  payload: {
    field: FORM_FIELDS,
    data: any,
  },
  type: string
}

const initialState: FormType = {
  id: null,
  name: '',
  startDate: undefined,
  endDate: undefined,
  question: '',
  options: [],
  pollGroupType: POLL_CATEGORY_TYPE.LOCATION,
  groupIds: [],
  isMandatory: 0,
  isPublishReady: 1,
  isResultVisible: 0,
}

export const pollsFormSlice = createSlice({
  name: 'pollsForm',
  initialState,
  reducers: {
    setPollsFormField: (state: any, action: SetFormActionType) => {
      state[action.payload.field] = action.payload.data;
    },
    clearAllPollsFormFields: (state) => {
      state.id = null;
      state.name = '';
      state.startDate = undefined;
      state.endDate = undefined;
      state.question = '';
      state.options = [];
      state.groupIds = [];
      state.isMandatory = 1;
      state.isPublishReady = 1;
      state.isResultVisible = 0;
    }
  }
});

export const {
  setPollsFormField,
  clearAllPollsFormFields
} = pollsFormSlice.actions;

export default pollsFormSlice.reducer;