/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../store/store';
import { setPollsFormField } from '../../../../store/slices/forms/pollsForm';

function CheckboxesPolls() {
  const { pollsForm } = useSelector((state: RootState) => state);
  const dispatch = useAppDispatch();

  // Handlers for the checkboxes
  const handleCompulsoryChange = () => dispatch(setPollsFormField({ field: 'isMandatory', data: 1 - pollsForm.isMandatory }));
  const handleVisibilityChange = () => dispatch(setPollsFormField({ field: 'isResultVisible', data: 1 - pollsForm.isResultVisible }));

  return (
    <div className='mt-4'>
      <div style={styles.checkboxContainer}>
        <label style={styles.label}>
          Make it compulsory for users to complete the poll
          <input
            type="checkbox"
            checked={pollsForm.isMandatory === 1}
            onChange={handleCompulsoryChange}
            style={styles.checkbox}
          />
        </label>
      </div>

      <div style={styles.checkboxContainer}>
        <label style={styles.label}>
          Make the results visible to users
          <input
            type="checkbox"
            checked={pollsForm.isResultVisible === 1}
            onChange={handleVisibilityChange}
            style={styles.checkbox}
          />
        </label>
      </div>
    </div>
  );
}

const styles = {
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
  },
  label: {
    fontSize: '14px',
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    cursor: "pointer"
  },
  checkbox: {
    marginLeft: '16px',
    accentColor: '#0491BD',
    transform: 'scale(1.2)',
    cursor: "pointer"
  },
};

export default CheckboxesPolls;
