import {FC, useEffect, useState, useCallback} from 'react'
import { useSelector } from 'react-redux'
import {EmissionTrendsChart} from '../../../_metronic/partials/components/chart/EmissionTrendsChart'
import {getYYYYDDMMFormat} from '../../utils/dateFormatter'
import {DateRangeType} from '../../../_metronic/partials/components/types'
import {useLazyGetEmissionTrendsQuery} from './store/api'
import {ChartValueTypes} from './type'
import {getLocalStorageItem} from '../../utils/storage'
import { RootState } from '../../store/store'
import { generateCompareMonthsRange, compareDefaultMonth, getAllDatesOfCurrentMonth } from '../../utils/dateFormatter'

type dateObjType = {
  startDate: string, 
  endDate: string
}

const getCompareMonthStartEndDates = (allMonths:any, month:string, setProgress:any) => {
  const ret = {
    startDate: '', 
    endDate: ''
  }
  allMonths?.forEach((item:any) => {
    if (item.month === month) {
      ret.startDate = item.startDate
      ret.endDate = item.endDate
    }
  })
  if (ret.startDate === '' && allMonths.length>0) {
    setProgress(allMonths[0].month)
    ret.startDate = allMonths[0].startDate
    ret.endDate = allMonths[0].endDate
  }
  return ret
}

const EmissionTrends = () => {
  const liveOnDate = new Date(getLocalStorageItem('live-on'))
  const { filterDate, filterMonth, filterYear } = useSelector((state: RootState) => state.filters)
  const { profile } = useSelector((state: RootState) => state.profile)

  const [getEmissionTrend, {data: emissionTrend}] = useLazyGetEmissionTrendsQuery()
  const [getEmissionTrendCompareToMonth, {data: emissionTrendCompareToMonth}] =
    useLazyGetEmissionTrendsQuery()

  const [monthsToShow, setMonthsToShow] = useState<any>([])
  const [isOnlyMonth, setIsOnlyMonth] = useState<boolean>(false)
  const [progress, setProgress] = useState<string>(compareDefaultMonth[filterMonth])
  const [compareDates, setCompareDates] = useState<dateObjType>({startDate: '', endDate: ''})
  const [chartValues, setChartValues] = useState<ChartValueTypes>()

  useEffect(() => {
    if (filterDate.startDate !== '' && filterMonth !== 'All') {
      setMonthsToShow(generateCompareMonthsRange(filterDate, filterMonth, filterYear, String(liveOnDate), profile?.organization?.isWorkshopOrg))
      setProgress(compareDefaultMonth[filterMonth])
    }
  }, [filterMonth, filterDate])

  useEffect(() => {
    if (monthsToShow.length===0) {
      setIsOnlyMonth(true)
    } else {
      setIsOnlyMonth(false)
    }
  }, [monthsToShow])

  useEffect(() => {
    if (monthsToShow.length>0) {
      setCompareDates(getCompareMonthStartEndDates(monthsToShow, progress, setProgress))
    }
  }, [progress, monthsToShow])

  useEffect(() => {
    if (filterDate.startDate !== '') {
      getEmissionTrend({
        fromDate: filterDate.startDate,
        toDate: filterDate.endDate,
      })
    }
  }, [filterDate])

  useEffect(() => {
    if (compareDates.startDate !== '') {
      getEmissionTrendCompareToMonth({
        fromDate: compareDates.startDate, 
        toDate: compareDates.endDate
      })
    }
  }, [compareDates])

  useEffect(() => {
    if (filterDate.startDate !== '') {
      const datesForCategory = getAllDatesOfCurrentMonth(filterDate.startDate)
      const values = new Array(datesForCategory.length).fill(0)
      // const datesForCategory: string[]  = [];
      const datesCompareToMonth = getAllDatesOfCurrentMonth(compareDates.startDate)
      const valuesForAnotherMonth = new Array(datesCompareToMonth.length).fill(0)

      if (emissionTrend?.length) {
        emissionTrend.forEach((trend, index) => {
          // const index = datesForCategory.findIndex((d) => {
          //   const date = new Date(trend.dates)
          //   date.setHours(0, 0, 0)
          //   return new Date(d).toISOString() === date.toISOString()
          // })
          values[index] = trend.emission
        })
      }

      if (compareDates.startDate !== '' && emissionTrendCompareToMonth?.length) {
        emissionTrendCompareToMonth.forEach((trend,index) => {
          // const index = datesCompareToMonth.findIndex((d) => {
          //   const date = new Date(trend.dates)
          //   date.setHours(0, 0, 0)
          //   return new Date(d).toISOString() === date.toISOString()
          // })
          valuesForAnotherMonth[index] = trend.emission
        })
      }

      const series = [
        {
          name: `Emissions for ${filterMonth} `,
          data: values,
        },
        {
          name: `Emissions for ${progress} `,
          data: valuesForAnotherMonth,
          type: 'line'
        },
      ]

      const categories = datesForCategory.map((date) =>
        new Date(date).toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'short',
          year: '2-digit',
        })
      )

      setChartValues({series, categories})
    }

  }, [emissionTrend, emissionTrendCompareToMonth, filterMonth, filterDate, compareDates, progress])

  const handleMonthSelect:any = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedMonth = e.target.value
    setProgress(selectedMonth)
  }

  return (
    <div className='card bordered-grey pb-0'>
      <div className='card-header border-0 pt-2' style={{marginRight: '30px'}}>
        {/* <h3 className='card-title align-items-center flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Emission Trends</span>
        </h3> */}
         <div />

        {/* begin::Wrapper */}
        {!isOnlyMonth &&
          <div className='d-flex align-items-center'>
            {/* begin::Label */}
            <span className='fs-6 fw-bold text-gray-700 flex-shrink-0 pe-4 d-none d-md-block'>
              Compared to
            </span>
            {/* end::Label */}

            {/* begin::Select */}
            <select
              className='form-select form-select-sm w-100 form-select-solid'
              data-control='select2'
              data-placeholder='Latest'
              data-hide-search='true'
              value={progress}
              onChange={handleMonthSelect}
              >
              {/* <option value="May">May</option> */}
              {monthsToShow.map((item:any) => (
                <option key={item.month} value={item.month}>
                  {item.month}
                </option>
              ))}
            </select>
            {/* end::Select */}
          </div>
          }
        {/* end::Wrapper */}
      </div>
      {chartValues && (
        <EmissionTrendsChart
          series={chartValues.series}
          categories={chartValues.categories}
          disableAnimation={chartValues.disableAnimation}
          isOnlyMonth={isOnlyMonth}
          />
      )}
    </div>
  )
}

export {EmissionTrends}
