import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';

import { DownloadIcon, TrashIcon2 } from '../../../../../_metronic/assets/icons';
import { ModifySVG } from '../../../../../_metronic/helpers/components/ModifySVG';
import { downloadAuditReportCSV, downloadTabularAuditReportCSV } from '../../../../utils/helpers';

export default function ModalDownloadAuditReport(props:any) {
  const { onHide, data } = props

  return (
    <Modal {...props} size='md' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Body>
        {/* <button type='button' className='remove-btn-styles modal-header-cross-btn' onClick={onHide}>
          <i className="bi bi-x-lg" style={{fontSize: '16px', color: 'black'}} />
        </button> */}
        <div className='position-absolute p-2' style={{backgroundColor: '#FFF', alignSelf: 'center', top: '-15%', left: '42%', borderRadius: '50%'}}>
          <div className='custom-tag-delete-modal-trash-wrap' style={{height: '65px', width: '65px'}}>
            <DownloadIcon style={{color: '#000', height: '45px', width: '45px'}} />
          </div>
        </div>
        <div className='d-flex align-items-center justify-content-center flex-column'>
          <h4 className='m-0 mt-8' style={{color: '#408FB9', fontWeight: '900', fontSize: '26px'}}>Download Report</h4>
          <h6 className='m-0 mt-4'>Choose the format in which you want to download the report</h6>
        </div>
        <div className='d-flex align-items-center justify-content-between mb-5 mt-8 px-2'>
          <div className='d-flex flex-end' style={{flexDirection: 'column', width: '48%'}}>
            <button
              type="button"
              className='remove-btn-styles custom-primary-delete-button w-100 mx-0'
              style={{fontWeight: '600', color: '#FFF', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
              onClick={() => {
                downloadAuditReportCSV(data);
                onHide();
              }}
            >
              <DownloadIcon style={{color: '#FFF', height: '16px', width: '16px', display: 'inline'}} />
              ROW-WISE RECORDS
            </button>
            <p className='my-0 mx-2' style={{fontSize: '12px', color: '#333333'}}>*For analysis</p>
          </div>
          <div className='d-flex flex-end' style={{flexDirection: 'column', width: '48%'}}>
            <button
              type="button"
              className='remove-btn-styles custom-primary-delete-button w-100 mx-0'
              style={{fontWeight: '600', color: '#FFF', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
              onClick={() => {
                downloadTabularAuditReportCSV(data);
                onHide();
              }}
            >
              <DownloadIcon style={{color: '#FFF', height: '16px', width: '16px', display: 'inline'}} />
              TABULAR
            </button>
            <p className='my-0 mx-2' style={{fontSize: '12px', color: '#333333'}}>*For printing</p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}