import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { RoutesPath } from '../../../../routing/RoutesPath';
import { 
  useLazyGetDivisionsListQuery,
  useLazyGetDepartmentsListQuery,
  useLazyGetLocationsListQuery
} from '../../../dashboard/store/api';
import { useLazyGetCustomGroupsListQuery } from '../../store/api';
import { RootState } from '../../../../store/store';
import { setActiveDepartmentsList, setLiveCustomGroupsList, setActiveLocationsList, setActiveDivisionsList } from '../../../../store/slices/masterList';
import { clearAllPollsFormFields, setPollsFormField } from '../../../../store/slices/forms/pollsForm';

import PartOneCreatePollPage from "./part-one-create-poll";
import PartTwoCreatePollPage from './part-two-create-poll';

type Props = {

}

const CreatePollsPage: React.FC<Props> = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {state: routeState}: any = useLocation();

  /* -------------------------------- REACT STATES ---------------------------------- */

  const { activeDepartmentsList, activeDivisionsList, activeLocationsList, liveCustomGroupsList } = useSelector((state: RootState) => state.masterList)

  const [onPartOne, setOnPartOne] = useState<number>(0);

  /* ------------------------- REDUX DISPATCHER, SELECTOR --------------------------- */

  const handleFormNavigation = (navigateTo: "part_one" | "part_two") => {
    if (navigateTo === "part_one") {
      setOnPartOne(1);
    } else {
      setOnPartOne(2);
    }
  }

  const [getDivisionsList, {data: divisionsListData}] = useLazyGetDivisionsListQuery()
  const [getDepartmentsList, {data: departmentsListData}] = useLazyGetDepartmentsListQuery()
  const [getLocationsList, {data: locationsListData}] = useLazyGetLocationsListQuery()
  const [getCustomGroupList, {data: customGroupsListData}] = useLazyGetCustomGroupsListQuery();

  /* ----------------------------------- HANDLERS ------------------------------------ */

  const handleBackNavigation = () => {
    dispatch(clearAllPollsFormFields());
    navigate(RoutesPath.POLLS.ROOT);
  }

  // REDUX MANAGEMENT AT COMPONENT MOUNT
  useEffect(() => {
    if (routeState && routeState?.status === "draft") {
      if (routeState?.id) 
        dispatch(setPollsFormField({field: "id", data: routeState?.id}));
      if (routeState?.name) 
        dispatch(setPollsFormField({field: "name", data: routeState?.name}));
      if (routeState?.question) 
        dispatch(setPollsFormField({field: "question", data: routeState?.question}));
      if (routeState?.options)
        dispatch(setPollsFormField({field: "options", data: routeState?.options}));
      if (routeState?.startDate) 
        dispatch(setPollsFormField({field: "startDate", data: new Date(routeState?.startDate)}));
      if (routeState?.endDate) 
        dispatch(setPollsFormField({field: "endDate", data: new Date(routeState?.endDate)}));
      if (routeState?.groupIds) 
        dispatch(setPollsFormField({field: "groupIds", data: routeState?.groupIds}));
      if (routeState?.isMandatory)
        dispatch(setPollsFormField({field: "isMandatory", data: routeState?.isMandatory}));
      if (routeState?.isResultVisible)
        dispatch(setPollsFormField({field: "isResultVisible", data: routeState?.isResultVisible}));
    } else {
      dispatch(clearAllPollsFormFields());
    }
    setOnPartOne(1);
  }, [routeState]);


  /* ------------------------- COMPONENT MOUNT - API CALLS --------------------------- */

  useEffect(() => {
    if (activeDivisionsList.length === 0) {
      getDivisionsList({isActive: true}).then((res:any)=>{
        const arr: any[] = []
        res?.data.forEach((itm:any) => {
          arr.push(itm)
        })
        dispatch(setActiveDivisionsList(arr))
      })
    }
    if (activeDepartmentsList.length === 0) {
      getDepartmentsList({isActive: true}).then((res:any)=>{
        const arr: any[] = []
        res?.data.forEach((itm:any) => {
          arr.push(itm)
        })
        dispatch(setActiveDepartmentsList(arr))
      })
    }
    if (activeLocationsList.length === 0) {
      getLocationsList({isActive: true}).then((res:any)=>{
        const arr: any[] = []
        res?.data.forEach((itm:any) => {
          arr.push(itm)
        })
        dispatch(setActiveLocationsList(arr))
      })
    }
    if (liveCustomGroupsList.length === 0) {
      getCustomGroupList({}).then((res:any)=>{
        let arr: any[] = Object.values(res?.data?.data?.groups || {});
        arr = arr.filter((item: any) => item.status === "live");
        dispatch(setLiveCustomGroupsList(arr));
      })
    }
  }, [])


  return (
    <div className='blank-page-view py-2 px-12'>

      {/* --------------------------------------- HEADER ------------------------------------------ */}

      <div className='back-header-btn mt-8' style={{marginLeft: '0px'}}>
        <button 
          type='button' 
          className='p-2 back-left-arrow-btn-wrap'
          onClick={handleBackNavigation}
          >
          <div className='back-left-arrow-btn' /> 
        </button>
        <h6 className='m-0 mx-4 fs-4 font-weight-normal'><u>Polls</u></h6>
      </div>

      {/* -------------------------------------- BODY - PAGES ---------------------------------------- */}

      {
        (onPartOne === 1) && (
          <PartOneCreatePollPage 
            handleFormNavigation={handleFormNavigation}
            /> 
        ) 
      }
      {
        (onPartOne === 2) && (
          <PartTwoCreatePollPage 
            handleFormNavigation={handleFormNavigation}
            /> 
        )
      }

    </div>
  )
}

export default CreatePollsPage;