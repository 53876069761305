import React from 'react'

const ParticipationPercentageCard = ({totalParticipants, totalUsers}) => {
  const participationPercentage = Math.round((totalParticipants / (totalUsers || 1)) * 100);

  return (
    <div className='custom-header-card pb-0' style={{marginRight: '15px'}}>
      <h5 className='m-0 mt-3' style={{color: '#FFF'}}>PARTICIPATION %</h5>
      <div className='d-flex flex-column m-0 p-0 position-relative'>
        {/* <h6 className='m-0 mt-2' style={{color: '#FFF', fontWeight: 400}}>PARTICIPATION %</h6> */}
        <div className='d-flex flex-column w-100 mb-5 align-items-center'>
          <div className='semi-donut-model2 mt-1' style={{'--percentage': `${participationPercentage > 100 ? 100 : participationPercentage}`}}>
            <div className='d-flex flex-column mt-0 align-items-center'>
              <span className='percent-val' style={{fontWeight: 800, fontSize: '32px', color: '#FFF'}}>
                {participationPercentage}<span style={{fontSize: '26px'}}>%</span>
              </span>
              <span className='p-1' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ParticipationPercentageCard;