/* eslint-disable global-require */
/* eslint-disable consistent-return */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
import {useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import moment from 'moment';
import { useSelector } from 'react-redux';

import { useLazyGetAttributesQuery } from '../store/api';
import { RoutesPath } from '../../../routing/RoutesPath';
import { commaSeparatedFormat } from '../../../utils/helpers';
import { useLazyGetLocationsListQuery } from '../../dashboard/store/api';
import { RootState, useAppDispatch } from '../../../store/store';
import { setLocationsList } from '../../../store/slices/masterList';

import ReactTable from '../../../../_metronic/partials/components/tables/ReactTable';

import './styles.css';

const energyTypeLabels:any = {
  'electricity': 'Electricity',
  'natural_gas': 'Natural Gas'
}

const transformAttributeData = (data: any[], locationsList: any[]) => {
  const result: any[] = [];
  data.forEach((item: any) => {
    const locationName = locationsList.find((loc) => loc.id === item.groupIds[0])?.name || "--"
    result.push({
      id: item.id,
      attributeName: item.name,
      createdAt: item.createdAt,
      startDate: item.startDate,
      groupIds: locationName,
      endDate: item.endDate,
      value: item.value,
      type: item.wasteType,
      unitType: item.unitType,
    })
  });
  return result;
}

const RenderDate = ({value}: {value:string}) => (
  <div>{moment(value?.slice(0,10)).format('DD/MM/YYYY')}</div>
)

const RenderName = ({value, onClickHandler}: {value:string, onClickHandler:()=>void}) => (
  <button
    type="button"
    className="remove-btn-styles"
    style={{color: '#1F7F9D'}}
    onClick={onClickHandler}
    >
    {value}
  </button>
)

const RenderGroup = ({value}: {value:string}) => (
  <div>{value}</div>
)

const RenderType = ({value}: {value:string}) => (
  <div>{value}</div>
)

const RenderStartDate = ({value}: {value:string}) => (
  <div>{moment(value?.slice(0,10)).format('DD/MM/YYYY')}</div>
)

const RenderEndDate = ({value}: {value:string}) => (
  <div>{moment(value?.slice(0,10)).format('DD/MM/YYYY')}</div>
)

const RenderValue = ({value}: {value:string}) => (
  <div>{commaSeparatedFormat(value)}</div>
)

const AttributesPage = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {locationsList} = useSelector((state: RootState) => state.masterList);
  
  const [tableData, setTableData] = useState<any[]>([]);

  const [getAttributeData, {
    data: attributeData,
    isLoading: isLoadingGetAttributeData,
    isSuccess: isSuccessGetAttributeData,
    isError: isErrorGetAttributeData
  }] = useLazyGetAttributesQuery();
  const [getLocationsList, {data: locationsListData}] = useLazyGetLocationsListQuery();

  useEffect(() => {
    if (attributeData?.data) {
      setTableData(transformAttributeData(attributeData?.data, locationsList));
    }
  }, [attributeData, locationsList])

  useEffect(() => {
    if (locationsList.length === 0) {
      getLocationsList({}).then((res:any)=>{
        const arr: any[] = [];
        res?.data.forEach((itm:any) => {
          arr.push(itm)
        })
        dispatch(setLocationsList(arr));
      })
    }
  }, [])

  const headers = useMemo(
    () => [
      {
        Header: 'NAME', 
        accessor: 'attributeName',  
        Cell: ({cell}: {cell: any}) => (
          <RenderName 
            value={cell.row.original.attributeName} 
            onClickHandler={() => navigate(RoutesPath.ATTRIBUTES.DETAILS, {state: cell.row.original})} 
            />
        )
      },
      {
        Header: 'START DATE',
        accessor: 'startDate',
        Cell: ({cell}: {cell: any}) => <RenderStartDate value={cell.row.original.startDate} />
      },
      {
        Header: 'END DATE',
        accessor: 'endDate',
        Cell: ({cell}: {cell: any}) => <RenderEndDate value={cell.row.original.endDate} />
      },
      {
        Header: 'LOCATION', 
        accessor: 'location', 
        Cell: ({cell}: {cell: any}) => <RenderGroup value={cell.row.original.groupIds} />
      },
      {
        Header: 'CREATED AT', 
        accessor: 'createdAt', 
        Cell: ({cell}: {cell: any}) => <RenderDate value={cell.row.original.createdAt} />
      },
      {
        Header: 'WASTE TYPE', 
        accessor: 'type',  
        Cell: ({cell}: {cell: any}) => <RenderType value={cell.row.original.type} />
      },
      {
        Header: 'VALUE (KG)',
        accessor: 'value',
        Cell: ({cell}: {cell: any}) => <RenderValue value={cell.row.original.value} />
      },
    ],
    []
  )

  useEffect(() => {
    getAttributeData({});
  }, []);

  return (
    <div className='blank-page-view py-8 px-12'>
      <div className='d-flex align-items-center justify-content-between'>
        <h1 className='page-title-text'>WASTE MANAGEMENT</h1>
        <button
            type='button'
            // disabled={!loading}
            className='custom-primary-delete-button m-0'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            onClick={()=>{navigate(RoutesPath.ATTRIBUTES.CREATE_ATTRIBUTES)}}
          >
          Add New
        </button>
      </div>
      <div className=''>
        <ReactTable
          headerData={headers}
          tableData={tableData}
          className='mt-6 custom-border'
          currentPageIndex={0}
          manualSort
          disableSort
          showPagination
          message={intl.formatMessage({id: 'CONFIGURATION.ATTRIBUTES.NO_RESULTS_FOUND'})}
          />
      </div>
      <div className='cloud-upload-bg bg-image-bottom-right' />
    </div>
  )
}

export default AttributesPage;
/* eslint-disable consistent-return */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
/* eslint-disable global-require */

