/* eslint-disable no-nested-ternary */
import React, {useState, useEffect, useRef, useCallback} from 'react';
import { ApexOptions } from 'apexcharts';
import { useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { toPng } from 'html-to-image';
import { RootState } from '../../../../app/store/store';

import { DownloadIcon } from '../../../assets/icons';
import { commaSeparatedFormat } from '../../../../app/utils/helpers';

export enum ChartType {
  DONUT= "DONUT",
  TREND_LINE= "TREND_LINE",
  TREND_LINE_COMPARE = "TREND_LINE_COMPARE",
  CONTRIBUTION_BAR= "CONTRIBUTION_BAR",
  ACTIVITIES_BAR= "ACTIVITIES_BAR",
  TOP_CATEGORIES= "TOP_CATEGORIES",
  TOP_ACTIVITIES= "TOP_ACTIVITIES",
}

type PropsType = {
  show: boolean,
  onHide: () => void,
  chartref: any,
  ChartComponent?: any,
  chartType: ChartType,
  data: any,
  refresh?: any,
}

const getContainerWidthClass = (chartType: ChartType): string => {
  let className = '100%';
  switch (chartType) {
    case ChartType.DONUT:
      className = '60%';
      break;
    default:
      className = '100%';
      break;
  }
  return className;
}

const ChartHeader = (props: {chartTitle: string, dateRange: string}) => {
  const { chartTitle, dateRange } = props;
  return (
    <div className='m-0 my-2'>
      <h4 className='m-0' style={{textAlign: "center", color: '#161616', fontWeight: '900', fontSize: '20px'}}>{chartTitle}</h4>
      <p className='m-0 mt-1' style={{textAlign: "center", color: "grey", fontWeight: '800'}}>{dateRange}</p>
    </div>
  )
}

const ChartFooter = (props: {chartType: ChartType, data: any}) => {
  const { chartType, data } = props;
  if (chartType !== ChartType.DONUT) return null;
  const sortedData = [...data.data].sort((a, b) => b.emission - a.emission)
  const topThreeItems = sortedData.slice(0, 3)
  const {labelColors} = data;
  return (
    <div className='my-2 mx-6 d-flex justify-content-between flex-row'>
      {
        topThreeItems.map((item: any, index: number) => (
          <>
            <div className='m-0'>
              <div key={`label${item.name}`} className='d-flex align-items-center m-0'>
                <div className='my-0' style={{backgroundColor: labelColors[index], height: '10px', width: '10px', borderRadius: '20%'}} />
                <p className='mx-1 my-0' style={{color: 'grey', width: '90px', fontSize: '12px', fontWeight: '700'}}>{item.name}</p>
              </div>
              <p style={{color: '#444', fontSize: '12px', fontWeight: '700'}}>{`${commaSeparatedFormat(item?.emission || 0)} kgCO2`}</p>
            </div>
            {
              [0, 1].includes(index) && <div className='mx-4 my-0 d-flex' style={{backgroundColor: 'lightgrey', width: '1px', height: '44px'}} />
            }
          </>
        ))
      }
    </div>
  )
}

export default function ModalDownloadChart(props: PropsType) {
  const { onHide, show, chartref, chartType, data, ChartComponent } = props;
  const {filterMonth, filterYear} = useSelector((state: RootState) => state.filters);
  const [showChart, setShowChart] = useState<boolean>(true);
  const [dateRange, setDateRange] = useState<string>('');

  const divRef = useRef<HTMLDivElement>(null);

  const handleDownloadChart = useCallback(() => {
    if (divRef.current === null) {
      return;
    }
    // apply changes to div before downloading
    const previousBkgColor = divRef.current.style.backgroundColor;
    divRef.current.style.backgroundColor = "white";

    toPng(divRef.current, {
      fetchRequestInit: {
        method: 'GET',
        cache: 'no-cache'   // <-- Important!
      }
    })
    .then((dataUrl) => {
      const link = document.createElement('a')
      link.download = 'chart.png'
      link.href = dataUrl
      link.click()
    })
    .catch((err) => {
      console.log(err)
    });

    setTimeout(() => {
      if (divRef.current) {
        // revert applied chaneges to div after download complete
        divRef.current.style.backgroundColor = previousBkgColor;
        onHide();
      }
    }, 100);

  }, [divRef]);

  useEffect(() => {
    if (filterMonth && filterYear) {
      let dateRangeText = `${filterMonth}, ${filterYear}`;
      if (filterMonth === 'All' && filterYear === 'All') dateRangeText = 'All Time';
      else if (filterMonth === 'All' && filterYear!== 'All') dateRangeText = `${filterYear}`;
      setDateRange(dateRangeText);
    }
  }, [filterMonth, filterYear]);

  useEffect(() => {
    setShowChart(false);
    setTimeout(() => {
      setShowChart(true);
    }, 3000);
  }, [show])

  return (
    <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Body>
        {/* <button type='button' className='remove-btn-styles modal-header-cross-btn' onClick={onHide}>
          <i className="bi bi-x-lg" style={{fontSize: '16px', color: 'black'}} />
        </button> */}
        <div className='position-absolute d-flex align-items-center justify-content-center w-100' style={{top: "-30px", left: "0px"}}>
          <div className='p-2' style={{backgroundColor: '#FFF', alignSelf: 'center', borderRadius: '50%'}}>
            <div className='custom-tag-delete-modal-trash-wrap' style={{height: '65px', width: '65px'}}>
              <DownloadIcon style={{color: '#000', height: '45px', width: '45px'}} />
            </div>
          </div>
        </div>
        <div className='d-flex align-items-center justify-content-center flex-column pt-10'>
          {/* <h4 className='m-0 mb-2' style={{color: '#408FB9', fontWeight: '900', fontSize: '26px'}}>Preview</h4> */}
          <div ref={divRef} style={{borderStyle: 'solid', borderWidth: '1px', borderColor: 'lightgrey', borderRadius: '10px', width: `${getContainerWidthClass(chartType)}`}}>
            {/* {chartComponent} */}
            <ChartHeader chartTitle={data.title} dateRange={dateRange} />
            <div className='card-body-chart d-flex flex-column mb-2'>
              {
                [ChartType.TOP_CATEGORIES, ChartType.TOP_ACTIVITIES].includes(chartType) ? (
                  <ChartComponent data={data.data} hideIcon={chartType === ChartType.TOP_ACTIVITIES} />
                ) : (
                  <>
                    <div ref={chartref} className='flex-grow-1' style={{display: showChart ? 'block' : 'none'}} />
                    {
                      !showChart && (
                        <div className='my-8 d-flex justify-content-center align-items-center flex-column' style={{height: '100%'}}>
                          <div className='spinner-border text-primary' role='status'>
                            <span className='visually-hidden'>Loading...</span>
                          </div>
                          <p className='m-0 mt-2' style={{color: 'grey', fontWeight: '600', fontSize: '14px'}}>Preparing chart for preview...</p>
                        </div>
                      )
                    }
                  </>
                )
              }
            </div>
            {
              showChart && <ChartFooter chartType={chartType} data={data} />
            }
          </div>
        </div>
        <div className='d-flex align-items-center justify-content-center mb-0 mt-1 px-2 pb-0'>
          {
            (showChart || [ChartType.TOP_CATEGORIES, ChartType.TOP_ACTIVITIES].includes(chartType)) && (
              <button
                type="button"
                className='remove-btn-styles custom-primary-delete-button w-40 mx-0'
                style={{fontWeight: '600', color: '#FFF', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                onClick={handleDownloadChart}
              >
                <DownloadIcon style={{color: '#FFF', height: '16px', width: '16px', display: 'inline'}} />
                Download as Image
              </button>
            )
          }
        </div>
      </Modal.Body>
    </Modal>
  )
}