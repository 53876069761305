
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

import { RootState } from '../../../../store/store'
import { PollFilterType, updatePollsFilterType } from '../../../../store/slices/filters'

export const FilterExistingPolls = () => {
  const categoriesFilter: PollFilterType[] = ["live", "upcoming", "draft", "completed"];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {filterPollType} = useSelector((state: RootState) => state.filters);

  const handleFilterLeaderboardCategory = (value: PollFilterType) => {
    dispatch(updatePollsFilterType(value));
  }

  return (
    <div className='d-flex mx-0 px-0 mb-6'>
    {
      categoriesFilter.map((value: PollFilterType) => (
      <div key={value} className='justify-content-end' style={{marginRight: '10px'}}>
        <button
          type='button'
          // disabled={!loading}
          className={`${filterPollType === value ? 'custom-group-category-filter-button' : 'custom-group-category-filter-outline-button'} m-0 px-10`}
          style={{
            borderRadius: '8px',
            fontWeight: '700'
          }}
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
          onClick={() => {
            handleFilterLeaderboardCategory(value);
          }}
        >
          {value.toUpperCase()}
        </button>
      </div>
      ))
    }
    </div>
  )
}
