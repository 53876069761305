/* eslint-disable dot-notation */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { usePostPollMutation, useUpdatePollMutation } from '../../store/api';
import { useLazyGetAllEmployeesQuery } from '../../../employee/store/api';
import { ChallengesTitleImage } from '../../../../../_metronic/assets/images';
import { DoneCircularIcon } from '../../../../../_metronic/assets/icons';
import { RoutesPath } from '../../../../routing/RoutesPath';
import { RootState } from '../../../../store/store';
import { setPollsFormField } from '../../../../store/slices/forms/pollsForm';
import { 
  POLL_CATEGORY_TYPE, 
  PollCategoryType,
} from '../types';

import MultiSelectDropdown from '../components/multi-select-dropdown';

import "../styles.css";

type PropsType = {
  handleFormNavigation: (navigateTo: "part_one" | "part_two") => void
}

const RenderMemberCard: React.FC<any> = (props) => {
  const {name, email} = props;
  return (
    <div className='custom-tag-member-card' title={email || undefined}>
      <h6 className='m-0 mx-4'>{name}</h6>
    </div>
  )
}


const PartTwoCreatePollPage: React.FC<PropsType> = (props) => {

  const {handleFormNavigation} = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /* ----------------------------------- STATES ----------------------------------- */

  const {pollsForm} = useSelector((state: RootState) => state);
  const {activeDepartmentsList, activeDivisionsList, activeLocationsList, liveCustomGroupsList} = useSelector((state: RootState) => state.masterList);

  const [selectedParticipantEmployees, setSelectedParticipantEmployees] = useState<any[]>([]);
  const [selectedParticipantGroups, setSelectedParticipantGroups] = useState<any[]>([]);
  const [isValidGroupIds, setIsValidGroupIds] = useState<boolean>(true);
  const [isValidParticipantsEmp, setIsValidParticipantsEmp] = useState<boolean>(true);
  const [isCheckedSelectAll, setIsCheckedSelectAll] = useState<boolean>(false);

  const [isLoadingGetEmployeeByCategory, setIsLodingGetEmployeesByCategory] = useState<boolean>(false);


  /* --------------------------------- RTK QUERY ------------------------------------- */

  const [getEmployeesByCategory, {
    data: employeesListByCategory,
  }] = useLazyGetAllEmployeesQuery();

  const [postPoll, {
    isLoading: isLoadingPostPoll,
    isSuccess: isSuccessPostPoll,
    isError: isErrorPostPoll
  }] = usePostPollMutation();

  const [updatePoll, {
    isLoading: isLoadingUpdatePoll,
    isSuccess: isSuccessUpdatePoll,
    isError: isErrorUpdatePoll
  }] = useUpdatePollMutation();

  /* ---------------------------------- HANDLERS ---------------------------------- */

  const getEmployeesListByCategory = () => {
    setSelectedParticipantEmployees([]);
    if (selectedParticipantGroups.length === 0) return;
    setIsLodingGetEmployeesByCategory(true);

    if (pollsForm.pollGroupType === POLL_CATEGORY_TYPE.GROUP) {
      const uniqueUsersMap = new Map();
      const selectCustomGroupIds = selectedParticipantGroups.map(grp => grp.id);

      liveCustomGroupsList.forEach((group: any) => {
        if (selectCustomGroupIds.includes(group.id)) {
          group?.users?.forEach((user: any) => {
            if (!uniqueUsersMap.has(user.id)) {
              uniqueUsersMap.set(user.id, user);
            }
          });
        }
      });

      setSelectedParticipantEmployees(Array.from(uniqueUsersMap.values()));
      setIsLodingGetEmployeesByCategory(false);
      return;
    }

    const payload: any = {
      sortBy: 'name',
      sortOrder: 'ASC',
      limit: 10000
    }
    if (pollsForm.pollGroupType === POLL_CATEGORY_TYPE.DEPARTMENT) payload.departmentId = selectedParticipantGroups.map(grp => grp.guid);
    if (pollsForm.pollGroupType === POLL_CATEGORY_TYPE.DIVISION) payload.divisionId = selectedParticipantGroups.map(grp => grp.guid);
    if (pollsForm.pollGroupType === POLL_CATEGORY_TYPE.LOCATION) payload.locationId = selectedParticipantGroups.map(grp => grp.guid);

    const handleSelectedEmployeesIntersection = (res: any) => {
      const data = res.data.edges;
      setSelectedParticipantEmployees((state: any) => (
        data.map((emp: any) => ({
          id: emp.id,
          name: emp.name,
          email: emp.email
        }))
      ))
    }

    getEmployeesByCategory(payload)
    .then((res: any) => {
      if (typeof res !== 'undefined') 
        handleSelectedEmployeesIntersection(res)
    })
    .finally(() => setIsLodingGetEmployeesByCategory(false))
  }

  const checkValidForm = () => {
    const validGroupIds = pollsForm.groupIds.length > 0;
    const validEmpParticipants = selectedParticipantEmployees.length > 1;
    const validGrpParticipants = selectedParticipantGroups.length > 1;

    if (!validGroupIds) setIsValidGroupIds(false);
    if (!validEmpParticipants) setIsValidParticipantsEmp(false);

    return validGroupIds && validEmpParticipants;
  }

  const handleSubmit = (action: "publish" | "save_as_draft") => {
    if (!checkValidForm())
      return;
      
    const payload: any = {
      "name": pollsForm.name,
      "start_date": moment(pollsForm.startDate).format('YYYY-MM-DD'),
      "question": pollsForm.question,
      "options": pollsForm.options,
      "group_type": pollsForm.pollGroupType,
      "group_ids": pollsForm.groupIds,
      "is_publish_ready": action === "publish" ? 1 : 0,
      "is_mandatory": pollsForm.isMandatory,
      "is_result_visible": pollsForm.isResultVisible,
    }
    if (pollsForm.endDate)
      payload["end_date"] = moment(pollsForm.endDate).format('YYYY-MM-DD');

    if (pollsForm?.id) {
      updatePoll({id: pollsForm.id, payload})
    } else {
      postPoll(payload);
    }
    setTimeout(() => {
      navigate(RoutesPath.POLLS.ROOT);
    }, 500);
  }

  const handleSelectAllGroupsCheckbox = () => {
    if (isCheckedSelectAll) {
      // clear fields
      setSelectedParticipantGroups([]);
    } else {
      // set all fields
      switch (pollsForm.pollGroupType) {
        case POLL_CATEGORY_TYPE.LOCATION: 
          setSelectedParticipantGroups(activeLocationsList);
          break;
        case POLL_CATEGORY_TYPE.DEPARTMENT: 
          setSelectedParticipantGroups(activeDepartmentsList);
          break;
        case POLL_CATEGORY_TYPE.DIVISION: 
          setSelectedParticipantGroups(activeDivisionsList);
          break;
        case POLL_CATEGORY_TYPE.GROUP: 
          setSelectedParticipantGroups(liveCustomGroupsList);
          break;
        default: 
          break;
      }
    }
    setIsCheckedSelectAll(state => !state);
  }

  useEffect(() => {
    switch (pollsForm.pollGroupType) {
      case POLL_CATEGORY_TYPE.LOCATION: 
        if (activeLocationsList.length !== selectedParticipantGroups.length) 
          setIsCheckedSelectAll(false);
        break;
      case POLL_CATEGORY_TYPE.DEPARTMENT: 
        if (activeDepartmentsList.length !== selectedParticipantGroups.length) 
          setIsCheckedSelectAll(false);
        break;
      case POLL_CATEGORY_TYPE.DIVISION: 
        if (activeDivisionsList.length !== selectedParticipantGroups.length) 
          setIsCheckedSelectAll(false);
        break;
      case POLL_CATEGORY_TYPE.GROUP: 
        if (liveCustomGroupsList.length !== selectedParticipantGroups.length) 
          setIsCheckedSelectAll(false);
        break;
      default: 
        break;
    }
  }, [selectedParticipantGroups])

  useEffect(() => {
    getEmployeesListByCategory();
    if (selectedParticipantGroups.length === 0) {
      dispatch(setPollsFormField({field: 'groupIds', data: []}));
    } else {
      dispatch(setPollsFormField({field: 'groupIds', data: selectedParticipantGroups.map((item: any) => item.id)}));
    }
  }, [selectedParticipantGroups]);

  useEffect(() => {
    if (pollsForm.groupIds.length > 0) {
      switch (pollsForm.pollGroupType) {
        case POLL_CATEGORY_TYPE.DEPARTMENT:
          setSelectedParticipantGroups(
            activeDepartmentsList.filter((item: any) => pollsForm.groupIds.includes(item.id)).map((item) => {
              const deptId: number = item.id;
              const deptName: string = item.name;
              const deptGuid: string = item.guid;
              return {
                id: deptId, 
                name: deptName,
                guid: deptGuid
              }
            })
          );
          break;
        case POLL_CATEGORY_TYPE.DIVISION:
          setSelectedParticipantGroups(
            activeDivisionsList.filter((item: any) => pollsForm.groupIds.includes(item.id)).map((item) => {
              const divId: number = item.id;
              const divName: string = item.name;
              const divGuid: string = item.guid;
              return {
                id: divId, 
                name: divName,
                guid: divGuid
              }
            })
          );
          break;
        case POLL_CATEGORY_TYPE.LOCATION:
          setSelectedParticipantGroups(
            activeLocationsList.filter((item: any) => pollsForm.groupIds.includes(item.id)).map((item) => {
              const locId: number = item.id;
              const locName: string = item.name;
              const locGuid: string = item.guid;
              return {
                id: locId, 
                name: locName,
                guid: locGuid
              }
            })
          );
          break;
        case POLL_CATEGORY_TYPE.GROUP:
          setSelectedParticipantGroups(
            liveCustomGroupsList.filter((item: any) => pollsForm.groupIds.includes(item.id)).map((item) => {
              const grpId: number = item.id;
              const grpName: string = item.name;
              return {
                id: grpId, 
                name: grpName
              }
            })
          );
          break;
        default: 
          break;
      }
    }
  }, [pollsForm.id])

  useEffect(() => {
    if (pollsForm.groupIds.length > 0) setIsValidGroupIds(true);
    if (selectedParticipantEmployees.length > 1) setIsValidParticipantsEmp(true);
  }, [pollsForm.groupIds, selectedParticipantEmployees, selectedParticipantGroups])

  return (
    <div className='mb-10'>

      {/* --------------------------------------- HEADER ------------------------------------------ */}

      <div className='d-flex align-items-center justify-content-center'>
        <ChallengesTitleImage height={80} width={100} className='mt-2' />
        <h2 className='m-0 px-6' style={{fontWeight: '800'}}>CREATE A POLL</h2>
      </div>
      <div className='my-6 d-flex align-items-center justify-content-center'>
        <button 
          type="button"
          className="position-relative remove-btn-styles"
          onClick={() => handleFormNavigation("part_one")}
          >
          <DoneCircularIcon height={24} width={24} color="#0491BD" />
          <p 
            className='position-absolute' 
            style={{
              fontWeight: '800',
              marginTop: '5px',
              color: '#666666',
              width: 'max-content',
              left: '-40px'
            }}
            >
              Poll description
          </p>
        </button>
        <div className='line-bar' style={{marginLeft: '4px', backgroundColor: "#0491BD"}} />
        <div className='line-bar' style={{marginRight: '4px', backgroundColor: "#0491BD"}} />
        <div className='position-relative'>
          <DoneCircularIcon height={24} width={24} color="#0491BD" />
          <p 
            className='position-absolute' 
            style={{
              fontWeight: '800',
              marginTop: '5px',
              color: '#666666',
              width: 'max-content',
              left: '-60px'
            }}
            >
            Participant&apos;s information
          </p>
        </div>
      </div>

      {/* --------------------------------------- BODY ------------------------------------------ */} 

      <form className='mt-10 mx-0 col'>

        {/* SELECT FROM :::: CATEGORY DROPDOWNS */}
        <div className='row m-0 p-0'>
          <div className='col-6 p-0'>
            <div className='d-flex justify-content-between mt-10 mb-4'>
              <h6 className='fs-5 m-0 p-0'>SELECT FROM</h6>
              <label className='m-0 p-0 d-flex align-items-center' style={{cursor: "pointer"}}>
                <span className='m-0' style={{paddingRight: '10px'}}>Select all</span>
                <input 
                  type='checkbox'
                  className='m-0 p-0'
                  style={{accentColor: "#0491BD", cursor: "pointer", height: "15px", width: "15px"}}
                  checked={isCheckedSelectAll} 
                  onChange={handleSelectAllGroupsCheckbox} 
                  />
              </label>
            </div>
            {/* CATEGORY DROPDOWN */}
            <select
              className='form-select m-0'
              onChange={(event: any) => {
                setIsCheckedSelectAll(false);
                setSelectedParticipantGroups([]);
                setSelectedParticipantEmployees([]);
                dispatch(setPollsFormField({field: "pollGroupType", data: event.target.value}));
              }}
              style={{border: '1px solid #bababa', color: "#202020"}}
              >
              {
                [POLL_CATEGORY_TYPE.LOCATION, POLL_CATEGORY_TYPE.DEPARTMENT, POLL_CATEGORY_TYPE.DIVISION, POLL_CATEGORY_TYPE.GROUP].map((item: PollCategoryType) => (
                  <option value={item} key={item} selected={pollsForm.pollGroupType === item}>
                    { 
                      item === POLL_CATEGORY_TYPE.GROUP ?
                      'Group' : 
                      `${item.slice(0,1)}${item.slice(1).toLowerCase()}`
                    }
                  </option>
                ))
              }
            </select>
          </div>
        </div>
              
        {/* CATEGORY DROPDOWNS */}
        <div className='row m-0 p-0'>
          <div className='col-6 p-0'>
            <h6 className='fs-5 mt-10 mb-4 p-0'>
              SELECT INDIVIDUALS FROM
            </h6>
            {/* DEPARTMENTS DROPDOWN */}
            {
              pollsForm.pollGroupType === POLL_CATEGORY_TYPE.DEPARTMENT && (
                <MultiSelectDropdown 
                  placeholder="Select Departments"
                  setSelectedOptions={setSelectedParticipantGroups}
                  selectedOptions={selectedParticipantGroups}
                  options={activeDepartmentsList.map((item: any) => ({
                    id: item.id, 
                    name: item.name,
                    guid: item.guid
                  }))}
                  />
              )
            }
            {/* DIVISIONS DROPDOWN */}
            {
              pollsForm.pollGroupType === POLL_CATEGORY_TYPE.DIVISION && (
                <MultiSelectDropdown
                  placeholder="Select Divisions"
                  setSelectedOptions={setSelectedParticipantGroups}
                  selectedOptions={selectedParticipantGroups}
                  options={activeDivisionsList.map((item: any) => ({
                    id: item.id, 
                    name: item.name,
                    guid: item.guid
                  }))}
                  />
              )
            }
            {/* LOCATIONS DROPDOWN */}
            {
              pollsForm.pollGroupType === POLL_CATEGORY_TYPE.LOCATION && (
                <MultiSelectDropdown
                  placeholder="Select Locations"
                  setSelectedOptions={setSelectedParticipantGroups}
                  selectedOptions={selectedParticipantGroups}
                  options={activeLocationsList.map((item: any) => ({
                    id: item.id, 
                    name: item.name,
                    guid: item.guid
                  }))}
                  />
              )
            }
            {/* CUSTOM GROUPS DROPDOWN */}
            {
              pollsForm.pollGroupType === POLL_CATEGORY_TYPE.GROUP && (
                <MultiSelectDropdown
                  placeholder="Select Groups"
                  setSelectedOptions={setSelectedParticipantGroups}
                  selectedOptions={selectedParticipantGroups}
                  options={liveCustomGroupsList.map((item: any) => ({
                    id: item.id, 
                    name: item.name,
                    guid: null
                  }))}
                  />
              )
            }
          </div>
        </div>

        {/* SELECTED PARTICIPANTS */}
        <div>
          <h6 className='fs-5 mt-10'>
            SELECTED PARTICIPANTS
            {
              (!isValidGroupIds || !isValidParticipantsEmp) &&
              <span style={{color: 'red', margin: '0px', marginLeft: '20px'}}>*Atleast 2 participants are required</span>
            }
          </h6>
          <div className='mt-4 wrap-create-polls-participant-input-container'>
            {
              isLoadingGetEmployeeByCategory &&
              <p style={{color: 'green', marginLeft: '0px'}}>Fetching employees...</p>
            }
            {
              selectedParticipantEmployees.map((item:any) => (
                <RenderMemberCard 
                  key={item?.id}
                  name={item?.name} 
                  email={item?.email}
                  />
              ))
            }
          </div>
        </div>
          

        <div className='my-8 d-flex align-items-center justify-content-evenly'>
          {
            !pollsForm?.id &&
            <button
              type='button'
              // disabled={!loading}
              className='custom-group-category-filter-button m-0 mt-8 px-14'
              style={{
                borderRadius: '8px',
                fontWeight: '700'
              }}
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
              onClick={() => handleSubmit("save_as_draft")}
              >
              SAVE AS DRAFT
            </button>
          }

          <button
            type='button'
            // disabled={!loading}
            className='custom-group-category-filter-button m-0 mt-8 px-20'
            style={{
              borderRadius: '8px',
              fontWeight: '700'
            }}
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            onClick={() => handleSubmit("publish")}
            >
            PUBLISH
          </button>
        </div>

      </form>

    </div>
  )
}

export default PartTwoCreatePollPage;
/* eslint-disable dot-notation */