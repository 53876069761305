/* eslint-disable no-useless-return */
/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

import { useLazyGetPollByIdQuery } from '../../store/api';
import { RoutesPath } from '../../../../routing/RoutesPath';
import { CalendarMonthIcon, IconEyeClose, IconEyeOpen, IconTickCompleteBlue, PollsTabIcon } from '../../../../../_metronic/assets/icons';
import { HeaderInfoCard } from '../../quizzes/components/header-info-card';
import ParticipationPercentageCard from '../components/header-card-participation-percent';

type PropsType = {

}

const IconCircleContainer = (props: any) => {
  const {children, content} = props;
  return (
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
      <div style={{
        backgroundColor: '#E2F3FB', 
        height: '30px',
        width: '30px', 
        borderRadius: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
        }}>
        {children}
      </div>
      <h5 className='m-0 px-2 bold' style={{color: '#5C5C5C'}}>
        {content}
      </h5>
    </div>
  )
}

const CheckIconCircleContainer = (props: any) => {
  const {children} = props;
  return (
    <div style={{
      backgroundColor: "#BAC2F4", 
      height: '20px',
      width: '20px', 
      borderRadius: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '10px'
      }}>
      {children}
    </div>
  )
}

const RenderPollStatusFlag = (props: any) => {
  const {status} = props;
  let shadowColor = '#E2F3FB';
  let bkgColor = '#A53838';
  let statusText = 'Ended';
  switch (status) {
    case "upcoming":
      bkgColor = '#DCA519';
      shadowColor = '#fae4ac';
      statusText = 'Upcoming';
      break;
    case "live":
      bkgColor = '#04BD38';
      shadowColor = '#E2F3FB';
      statusText = "Live";
      break;
    case "completed":
      bkgColor = '#A53838';
      shadowColor = '#f7cbcb';
      statusText = 'Completed';
      break;
    case "draft":
      bkgColor = '#84C0D2';
      shadowColor = '#d7f4fc';
      statusText = 'Draft';
      break;
    default:
      bkgColor = '#A53838';
      shadowColor = '#f7cbcb';
      statusText = 'Completed';
      break;
  }
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex align-items-center justify-content-center' style={{height: '20px', width: '20px', borderRadius: '50%', backgroundColor: shadowColor, marginRight: '8px'}}>
        <div 
          style={{
            height: '12px',
            width: '12px',
            borderRadius: "50%",
            backgroundColor: bkgColor,
          }}
        />
      </div>
      <h5 className='m-0 bold' style={{color: '#5C5C5C'}}>{statusText}</h5>
    </div>
  )
}

const OptionDiv = (props: any) => {
  const { item, backgroundColor, textColor } = props;
  
  return (
    <div 
      className='mb-6 d-flex align-items-center justify-content-between position-relative'
      style={{
        backgroundColor: '#bfbfbf', // background color for the full bar
        borderRadius: '28px',
        overflow: 'hidden',
        padding: '8px 18px', // padding for the overall container
      }}
    >
      {/* Background bar for the percentage width */}
      <div
        style={{
          backgroundColor,
          width: `${item.percent || 0}%`, // set width based on the percentage
          height: '100%', // make sure it covers the full height
          position: 'absolute', // position it absolutely within the container
          top: 0,
          left: 0,
          borderRadius: '28px', // match the rounded corners
          zIndex: 1, // ensure it stays behind the text
        }}
      />
      {/* <BackgrondHiddenText option={item.option} answer={item.answer} percent={item.percent} /> */}
      {/* Text content, positioned over the background */}
      <h5 className='bold m-0' style={{ color: textColor, zIndex: 3 }}>
        {item.option}. {item.answer}
      </h5>
      <h5 className='bold m-0' style={{ color: textColor, zIndex: 3 }}>
        {item.percent || 0}%
      </h5>
    </div>
  );
}

const AllOptions = (props: any) => {
  const { answers, result } = props;

  let totalParticipants = 0;
  result.forEach((item: any) => {totalParticipants += item.count});
  // Calculate percentages and sort answers by percent in descending order
  const answersWithPercentages = answers.map((item: any) => {
    const optionResult = result?.find((res: any) => res.option === item.option);
    const count = optionResult?.count || 0;
    const percent = totalParticipants > 0 ? Math.round((count / totalParticipants) * 100) : 0;
    return { ...item, percent };
  });

  // Create a sorted copy to determine ranks while preserving original order
  const sortedForRanking = [...answersWithPercentages].sort((a: any, b: any) => b.percent - a.percent);
  
  let currentRank = 1;
  let currentPercent = sortedForRanking[0]?.percent;
  const rankMap = new Map();

  // First pass: create a map of percent to rank handling ties
  sortedForRanking.forEach((answer: any) => {
    if (answer.percent < currentPercent) {
      currentRank = rankMap.size + 1;
      currentPercent = answer.percent;
    }
    rankMap.set(answer.percent, currentRank);
  });

  // Assign ranks to original array using the rank map
  answersWithPercentages.forEach((answer: any) => {
    answer.rank = rankMap.get(answer.percent);
  });

  // Define colors for the top 4 answers and corresponding text colors
  const colors = ['#15181F', '#6C83B8', '#B0B7E4', '#aa83fc'];
  const textColors = ['#FFF', '#FFF', '#FFF', '#FFF']; // black text for the last two colors

  return (
    answersWithPercentages.map((item: any, index: number) => (
      <OptionDiv 
        key={item.option} 
        item={item} 
        backgroundColor={colors[item.rank - 1] || '#a6a4a4'} // Default color for options beyond the top 4
        textColor={textColors[index] || '#FFF'} // Default text color for options beyond the top 4
      />
    ))
  );
}


const PollDetailsPage: React.FC<PropsType> = () => {
  const navigate = useNavigate();
  const {state: routeState}: any  = useLocation();

  const [getPollById, {
    data: pollData,
    isLoading: isLoadingGetPollById,
    isSuccess: isSuccessGetPollById,
    isError: isErrorGetPollById
  }] = useLazyGetPollByIdQuery();

  useEffect(() => {
    if (routeState.id)
      getPollById({id: routeState.id})
  }, [routeState.id])

  const getParticipationPercent = () => {
    let percent = 0;
    if (pollData?.data?.userIds?.length > 0) {
      const total = pollData?.data?.userIds?.length;
      if (typeof pollData?.data?.totalResponseCount !== "undefined")
        percent = Number(pollData?.data?.totalResponseCount || 0) / total;
    }
    return Math.round(percent * 100);
  }

  const getNonRespondentCount = () => {
    let count = 0;
    if (pollData?.data?.userIds?.length > 0) {
      const total = pollData?.data?.userIds?.length;
      if (typeof pollData?.data?.totalResponseCount !== "undefined") {
        const respondents = pollData?.data?.totalResponseCount || 0;
        count = total - respondents;
      }
    }
    return count;
  }

  return (
    <div className='blank-page-view py-2 px-12 mb-10'>

      {/* --------------------------------------- HEADER ------------------------------------------ */}

      <div className='back-header-btn mt-2' style={{marginLeft: '0px'}}>
        <button 
          type='button' 
          className='p-2 back-left-arrow-btn-wrap'
          onClick={()=>{navigate(RoutesPath.POLLS.ROOT)}}
          >
          <div className='back-left-arrow-btn' /> 
        </button>
        <h6 className='m-0 mx-4 fs-4 font-weight-normal'><u>Polls</u></h6>
      </div>

      <h1 className='m-0 mt-8 bold ' style={{color: '#5C5C5C'}}>
        {routeState.name.toUpperCase()}
      </h1>

      {/* ----------------------------------------- BODY ----------------------------------------------- */}

      <div className='d-flex justify-content-between mx-0 mt-6'>
        <ParticipationPercentageCard 
          totalParticipants={pollData?.data?.totalResponseCount || 0}
          totalUsers={pollData?.data?.userIds?.length || 0}
          />
        <HeaderInfoCard 
          type="white"
          label="TOTAL ELIGIBLE PARTICIPANTS"
          value={`${pollData?.data?.userIds?.length ? Math.round(Number(pollData?.data?.userIds?.length || 0)) : '--'}`}
          containerStyle={{width: '25%'}}
          />
        <HeaderInfoCard 
          type="white"
          label="TOTAL RESPONDENTS"
          value={pollData?.data?.totalResponseCount !== undefined ? pollData?.data?.totalResponseCount : '--'}
          containerStyle={{width: '25%'}}
          />
        <HeaderInfoCard 
          type="white"
          label="NON RESPONDENTS"
          value={`${pollData?.data?.userIds?.length ? getNonRespondentCount() : '--'}`}
          containerStyle={{width: '25%'}}
          />
      </div>

      <div className='my-8 d-flex justify-content-between'>
        <div className='bold d-flex align-items-center'>
          <RenderPollStatusFlag status={routeState.status} />
        </div>
        <div className='bold d-flex align-items-center'>
          <IconCircleContainer
            content={`${moment(routeState.startDate).format('DD/MM/YYYY')} ${routeState?.endDate ? `- ${moment(routeState.endDate).format('DD/MM/YYYY')}` : ''}`}
            >
            <CalendarMonthIcon color="#4C707A" />
          </IconCircleContainer>
        </div>
        {
          pollData?.data?.isResultVisible !== undefined ? (
            <div className='bold d-flex align-items-center'>
              <IconCircleContainer
                content={`${pollData?.data?.isResultVisible ? 'Poll results are visible to the users' : 'Poll results are not visible to the users'}`}
                >
                  {
                    pollData?.data?.isResultVisible ? (
                      <IconEyeOpen color="#4C707A" />
                    ) : (
                      <IconEyeClose color="#4C707A" />
                    )
                  }
              </IconCircleContainer>
            </div>
          ) : null
        }
        {
          pollData?.data?.isMandatory !== undefined ? (
            <div className='bold d-flex align-items-center'>
              <IconCircleContainer
                content={`${pollData?.data?.isMandatory ? 'Mandatory poll' : 'Optional poll'}`}
                >
                <PollsTabIcon color="#4C707A" />
              </IconCircleContainer>
            </div>
          ) : null
        }
      </div>

      <div className='pt-6 pb-4 px-8 w-100 bordered-grey position-relative'>
        <div className='mt-2'>
          {
            routeState?.question && (
              <h3 className='m-0 mb-8 bold' style={{color: '#5C5C5C'}}>
                {routeState?.question}
              </h3>
            )
          }
          {
            pollData?.data?.answers?.length && (
              <AllOptions 
                answers={pollData?.data?.answers || []}
                result={pollData?.data?.result || []}
                />
            )
          }
        </div>
      </div>

    </div>
  )
}

export default PollDetailsPage;
/* eslint-disable no-useless-return */
/* eslint-disable prefer-destructuring */